@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
/*@import 'https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css';*/










/*
    DEMO STYLE
*/
body {
    /*font-family: 'Poppins', sans-serif;*/
    background: #fafafa;
}
p {
    /*font-family: 'Poppins', sans-serif;*/
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1.7em;
}
a, a:hover, a:focus {
    text-decoration: none;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.navbar {
    /*padding: 15px 10px;*/
    background: #fff;
    border: none;
    border-radius: 0;
    margin-bottom: 40px;
    -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
            box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}
.navbar-btn {
    -webkit-box-shadow: none;
            box-shadow: none;
    outline: none !important;
    border: none;
}
.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
}










/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
#sidebar {
    width: 60vw;
    position: fixed;
    top: 0;
    left: -60vw;
    height: 100vh;
    z-index: 999;
    /*background: #0171bbBB;*/
    background: #fdf7dc;
    /*background-color: red;*/
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    overflow-y: scroll;
    -webkit-box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
            box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
}
#sidebar.active {
    left: 0;
}
#dismiss {
    width: 4.8vh;
    height: 4.8vh;
    line-height: 45px;
    text-align: center;
    /*background: #549CCB;*/
    position: absolute;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.overlay {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(10, 10, 10, 0.6);
    z-index: 998;
    display: none;
}
#sidebar .sidebar-header {
    /*padding: 20px;*/
    padding-top: 0;
    background: #549CCB;
    text-align: center;
}
#sidebar ul.components {
    /*padding: 20px 0;*/
    border-bottom: 1px solid #47748b;
}
#sidebar ul p {
    color: #fff;
    /*padding: 10px;*/
}
#sidebar ul li a {
    /*padding: 10px;*/
    font-size: 1.1em;
    display: block;
}
#sidebar ul li a:hover {
    background: #fff;
}
#sidebar ul li.active > a, a[aria-expanded="true"] {
}
a[data-toggle="collapse"] {
    position: relative;
}
a[aria-expanded="false"]::before, a[aria-expanded="true"]::before {
    content: '\E259';
    display: block;
    position: absolute;
    right: 20px;
    font-family: 'Glyphicons Halflings';
    font-size: 1.0em;
}
a[aria-expanded="true"]::before {
    content: '\E260';
}
ul ul a {
    font-size: 1.05em !important;
    padding-left: 15px !important;
}
ul.CTAs {
    padding: 20px;
}
ul.CTAs a {
    text-align: center;
    font-size: 1.0em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
}










/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
#content {
    width: 100%;
    /*padding: 20px;*/
    min-height: 100vh;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    position: absolute;
    top: 0;
    right: 0;
}
:root {
    /* TTT */
    /*--main-bg-color: #fdf7dc;*/
    /*--bg-header-color: #fcd842;*/
    --bg-header-color: white;
    /*--main-bg-color: #ffefbd;*/
    /*--main-bg-color: white;*/

    --size-header: 14vh;
    --size-header-m: 7vh;
    --sidebar-width: 60vw;
    /*--main-bg-color: #fff5ad;*/
    /*--main-bg-color: #ffef7b; !*usando esse*!*/
    /*--main-bg-color: #fff159; !*mercadolivre*!*/
    /*--main-bg-color: #ffe107;*/
    /*--main-bg-color: #efd306;*/
    /*--main-bg-color: #ffd106;*/
    /*--main-bg-color: #e8842f;*/
}
@font-face {
    font-family: 'FocoI'; /*a name to be used later*/
    src: url('/static/fonts/FocoW01BlackItalic.ttf'); /*URL to font*/
}
@font-face {
    font-family: 'FocoB'; /*a name to be used later*/
    src: url('/static/fonts/FocoW01Bold.ttf'); /*URL to font*/
}
@font-face {
    font-family: 'RamaE'; /*a name to be used later*/
    src: url('/static/fonts/RamaGothicEBold.ttf'); /*URL to font*/
}
#app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;

    font-size: 0;
}
html, body {
    background-color: var(--main-bg-color);
    /*background-color: red;*/
    margin: 0;
    padding: 0;
    font-size: 0;
}














































































h1[data-v-09972ea4], h2[data-v-09972ea4] {
    font-weight: normal;
    font-size: 20pt;
}
.search[data-v-09972ea4] {
    background-image: url('/static/imgs/bg_search01.png');
    background-color: transparent;
    background-repeat: no-repeat;
    /*background-size: contain;*/
    background-size: 100% 28px;
    border: none;
    outline:none;
    /*position: center;*/
    /*position: relative;*/
    /*margin-top: -15px;*/
    padding-left: 50px;
    width: 100%;
    height: 28px;
    max-width: 662px;
}
.rootview[data-v-09972ea4] {
    display: inline-block;
    /*background-color: red;*/
    /*background-color: lightgray;*/

    width: 100%;
    height: 100vh;
}
.rootviewm[data-v-09972ea4] {
    display: inline-block;
    /*background-color: red;*/
    /*background-color: lightgray;*/

    width: 100%;
    height: 100vh;
}
.rootviewm_compra[data-v-09972ea4] {
    display: inline-block;
    /*background-color: red;*/
    /*background-color: lightgray;*/

    width: 100%;
    height: auto;
    border-color: #CCC;
    border-width: 1px;
    border-style: solid;
    min-height: 100vh;
}
.header[data-v-09972ea4] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    /*background-color: #ffe106;*/
    width: 100%;
    margin-bottom: 3vh;

    /*font-size: 0;*/
    /*height: 30vh;*/
}
.header_p1_c[data-v-09972ea4], .header_p1_c_m[data-v-09972ea4] {
    /*background-color: #ffe106;*/
    /*background-color: #fcd842;*/
    /*background-color: green;*/
    /*background-color: var(--bg-header-color);*/
    background-image: url('/static/imgs/bg_cabecalho.jpg');
    background-size: cover;

    position: fixed;
    z-index: 2;
    height: var(--size-header);
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.header_p1_c_m[data-v-09972ea4] {
    top: 0;
    height: auto;
    /*background-color: green;*/
}
.header_p1[data-v-09972ea4], .header_p1_m[data-v-09972ea4] {
    /*background-color: darkred;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    width: 100%;
    height: var(--size-header);
    /*height: 100%;*/
}
.header_p1_m[data-v-09972ea4] {
    height: var(--size-header-m);
}
.bodyview[data-v-09972ea4] {
    display: inline-block;
    /*background-color: gray;*/
    /*background-color: green;*/
    width: 100%;
    height: auto;
    margin-bottom: 50px;
}
.side_right[data-v-09972ea4], .side_left[data-v-09972ea4] {
    /*background-color: var(--main-bg-color);*/
    /*background-color: #ffcf72;*/
    /*background-color: #fdd92c;*/
    /*background-color: #fdf7dc;*/
}
.side_left[data-v-09972ea4], .side_left_m[data-v-09972ea4] {
    /*background-color: yellow;*/
    width: 24vw;
    padding-left: 1vw;
    text-align: left;
    float: left;
    height: 100%;
}
.side_left[data-v-09972ea4] {
    border-width: 0.1vw;
    border-color: darkgray;
    border-style: none solid none none;
}
.side_left_m[data-v-09972ea4] {
    margin-top: 2vh;
    width: 100%;
    padding-left: 2vw;
}
.side_right[data-v-09972ea4] {
    /*background-color: darkred;*/
    width: 75vw;
    height: auto;
    margin-left: 25vw;
    border-width: 0.1vw;
    border-color: darkgray;
    border-style: none none none solid;
}
.side_left_h[data-v-09972ea4], .side_middle_h[data-v-09972ea4], .side_right_h[data-v-09972ea4], .side_right_h_m[data-v-09972ea4] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*flex: 1 1 0;*/
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    /*flex-shrink: 1;*/
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
}
.side_left_h[data-v-09972ea4] {
    /*background-color: blue;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*align-items: center;*/
    /*margin-left: 1.5vw;*/
}
.side_middle_h[data-v-09972ea4] {
    /*background-color: pink;*/
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-flex: 1.6;
        -ms-flex-positive: 1.6;
            flex-grow: 1.6;
}
.side_right_h[data-v-09972ea4], .side_right_h_m[data-v-09972ea4] {
    /*background-color: yellow;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    /*justify-content: space-between;*/
}
.side_right_h_m[data-v-09972ea4] {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.div_share[data-v-09972ea4], .div_share_central[data-v-09972ea4], .div_share_m[data-v-09972ea4], div_share_central_m[data-v-09972ea4] {
    position: absolute;
}
.div_share[data-v-09972ea4] {
    /* proporcional ao .div_oferta que tem tamanho 28vh*/
    margin-left: 16vh;
}
.div_share_central[data-v-09972ea4] {
    /* proporcional ao .div_oferta_central que tem tam 50%*/
    margin-left: 43%;
}
.div_share_m[data-v-09972ea4] {
    /* proporcional ao .div_oferta_m que tem tamanho 70vmin*/
    margin-left: 46vmin;
}
.div_share_central_m[data-v-09972ea4] {
    /* proporcional ao .div_oferta_central_m que tem tamanho 100%*/
    margin-left: 70%;
}
.div_cats[data-v-09972ea4] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    /*background-color: red;*/
    margin-left: 0.5vw;
}
.div_subcats[data-v-09972ea4] {
    margin-top: 0.75vh;
    margin-bottom: 0.75vh;
    /*background-color: purple;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.div_cats[data-v-09972ea4]:hover {
    background-color: #ffcf72;
    /*background-color: #fdd92c;*/
    /*background-color: #ffe106;*/
}
.div_oferta[data-v-09972ea4], .div_oferta_m[data-v-09972ea4], .div_oferta_m_card[data-v-09972ea4] {
    /*position: relative;*/
    margin-right: 3vh;
    margin-left: 3vh;
    margin-top: 3vh;
    display: inline-block;
    /*background-color: red;*/
    background-color: white;
    -webkit-box-shadow: 3px 3px 3px lightgray;
            box-shadow: 3px 3px 3px lightgray;
    padding: 2vh;
    width: 28vh;
    /*height: 38vh;*/
    min-height: 38vh;
    text-align: left;
}
.div_oferta_m[data-v-09972ea4], .div_oferta_m_card[data-v-09972ea4] {
    /*background-color: yellow;*/
    width: 70vmin;
    /*width: 60vmin;*/
}
.div_oferta_m_card[data-v-09972ea4] {
    background-color: white;
    /*background-color: pink;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    min-height: unset;
    min-height: 9vh;
    height: auto;
    max-height: 14vh;
    /*align-items: stretch;*/
    /*min-width: 96vw;*/
    width: 96%;
    padding: 2%;
    position: relative;

    margin-right: 0;
    margin-left: 0;
    margin-top: 0;
    border: 0px;
    border-top: 1px;
    border-color: #cccccc;
    border-style: solid;
    margin: 0;
    /*padding: 2vw;*/
}
.div_oferta_c[data-v-09972ea4], .div_oferta_c_m[data-v-09972ea4] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    text-align: left;
}
.div_oferta_c_m[data-v-09972ea4] {
    /*background-color: red;*/
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
}
.div_oferta_compra[data-v-09972ea4] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    text-align: left;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    /*background-color: yellow;*/
}
.div_timer[data-v-09972ea4] {
    /*background-image: url('/static/imgs/balloon2.png');*/
    /*background-repeat: no-repeat;*/
    /*background-size: 90% 120%;*/
    /*background-position: 50% 90%;*/
    /*height: 100%;*/
    /*flex-grow: 1;*/
    /*justify-content: center;*/

    /*background-color: #6b35c4;*/
    /*background-color: white;*/
    /*align-self: center;*/
    /*justify-self: center;*/
    /*align-items: center;*/
    /*align-content: center;*/
    /*border-color: #6b35c4;*/
    /*border-width: 3px;*/
    /*border-style: solid;*/
    /*border-radius: 15px;*/
    /*padding: 5px;*/
    /*margin-left: 15%;*/

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;

    /*margin-top: 1vw;*/
}
.div_timer_m[data-v-09972ea4] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.div_social[data-v-09972ea4], .div_social2[data-v-09972ea4] {
    /*background-color: blue;*/
    margin-top: 5vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*justify-content: center;*/
}
.div_social2[data-v-09972ea4] {
    /*background-color: purple;*/
    /*top: 7vh;*/
}
.div_app[data-v-09972ea4] {
    /*background-color: blue;*/
    margin-top: 2vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*justify-content: center;*/
}
.div_search[data-v-09972ea4] {
    /*height: 275px;*/
    /*max-height: 275px;*/
    text-align: center;
    /*background-color: red;*/
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    /*margin-bottom: -28px;*/
    margin-top: -15px;
    height: 0px;
    /*justify-self: center;*/
    /*align-self: center;*/
    /*transform: translateY(15px);*/
}
.div_oferta_central[data-v-09972ea4], .div_oferta_central_m[data-v-09972ea4] {
    /*background-color: red;*/
    background-color: #fff;
    -webkit-box-shadow: 3px 3px 3px black;
            box-shadow: 3px 3px 3px black;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 50%;
    max-height: 100vh;
    height: auto;
    overflow-x: scroll;
    padding-top: 1%;
    padding-bottom: 2%;
    padding-right: 2%;
    padding-left: 2%;
    margin-right: 2%;
    margin-left: 2%;
    text-align: left;
    z-index: 1001;
}
.div_oferta_central_m[data-v-09972ea4] {
    width: 100%;
}
.ofertaView[data-v-09972ea4], .ofertaView_card[data-v-09972ea4] {
    /*background-color: red;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: fixed;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow-x: auto;
    z-index: 3;
    /*max-height: 100vh;*/
}
.overlay_oferta[data-v-09972ea4], .overlay_oferta_card[data-v-09972ea4] {
    position: fixed;
    top: 0;
    opacity:0.8;
    background-color: black;
    width: 100vw;
    height: 100vh;
    z-index: 2;
}
.im_loading[data-v-09972ea4], .im_loading_end[data-v-09972ea4] {
    width: 80px;
    height: 80px;
    display: inline-block;
}
.im_loading_end[data-v-09972ea4] {
    width: 50px;
    height: 50px;
}
.im_thumb[data-v-09972ea4], .im_thumb_central[data-v-09972ea4], .im_thumb_card[data-v-09972ea4] {
    text-align: center;
    /*background-color: green;*/
    height: 25vh;
    max-width: 23vh;
    -o-object-fit: contain;
       object-fit: contain;
}
.im_thumb[data-v-09972ea4] {
    color-scheme: only light;
}
.im_thumb_card[data-v-09972ea4] {
    position: relative;
    max-width: unset;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 6px;
}
.im_thumb_m[data-v-09972ea4]{
    text-align: center;
    /*background-color: green;*/
    /*height: 25vh;*/
    width: 90%;
    /*max-width: 23vh;*/
    -o-object-fit: contain;
       object-fit: contain;
}
.im_thumb_central[data-v-09972ea4] {
    height: 55vh;
    max-width: 90%;
}
.im_thumb_central_m[data-v-09972ea4] {
    width: 100%;
    text-align: center;
    max-height: 70vmax;
    -o-object-fit: contain;
       object-fit: contain;
    /*background-color: red;*/
}
.im_thumb_mini_central[data-v-09972ea4] {
    border-width: 2px;
    border-bottom-color: black;
    border-style: solid;
    width: 50px;
    height: 50px;
    -o-object-fit: contain;
       object-fit: contain;
}
.im_cat[data-v-09972ea4], .im_cat_selected[data-v-09972ea4] {
    text-align: center;
    /*background-color: red;*/
    width: 2.5vh;
    margin-right: 1vh;
    -o-object-fit: contain;
       object-fit: contain;
    -webkit-filter: brightness(0.25);
            filter: brightness(0.25);
}
.im_cat_selected[data-v-09972ea4] {
    /*filter: contrast(0);*/
    -webkit-filter: brightness(0%);
            filter: brightness(0%);
}
.im_logo[data-v-09972ea4] {
    margin-left: 3vh;
    height: 11vh;
    /*height: 6.2vw;*/
}
.im_ttt[data-v-09972ea4] {
    width: 25vw;
    width: 44vh;
    -ms-flex-item-align: center;
        align-self: center;
}
.im_ttt2[data-v-09972ea4] {
    height: 12vh;
    -ms-flex-item-align: start;
        align-self: flex-start;
}
.im_ttt_m[data-v-09972ea4] {
    width: 30vh;
    -ms-flex-item-align: center;
        align-self: center;
    -o-object-fit: contain;
       object-fit: contain;
}
.im_social[data-v-09972ea4] {
    height: 5vh;
    width: 5vh;
    margin-right: 1vh;
    margin-left: 1vh;
}
.im_app[data-v-09972ea4] {
    height: 8vh;
    width: 8vh;
    margin-right: 1vh;
    margin-left: 1vh;
}
.im_banner[data-v-09972ea4], .im_banner_m[data-v-09972ea4], .im_banner_m_app[data-v-09972ea4] {
    /*top: 0px;*/
    margin-top: var(--size-header);
    width: 100%;
    /*height: 100%;*/
}
.im_banner_m[data-v-09972ea4] {
    margin-top: calc(var(--size-header-m) - 1px);
    /*margin-top: var(--size-header-m);*/
}
.im_banner_m_app[data-v-09972ea4] {
    margin-top: 0;
}
.im_balloom[data-v-09972ea4] {
    position: fixed;
    /*margin-top: 25%;*/
    -ms-flex-item-align: center;
        align-self: center;
    /*width: 85%;*/
    z-index: 2;
}
.im_menu[data-v-09972ea4] {
    width: 4vh;
    height: 4vh;
    margin-left: 1vh;
    /*margin-top: 1vh;*/
    vertical-align: middle;
    -o-object-fit: contain;
}
#dismiss[data-v-09972ea4] {
    margin-top: 1.4vh;
}
.im_profile[data-v-09972ea4], .im_profile_l[data-v-09972ea4] {
    /*background-color: red;*/
    position: fixed;
    right: 2px;
    top: 2px;
    height: 5vh;
    width: 5vh;
}
.im_profile_l[data-v-09972ea4] {
    top: 0px;
    margin-top: 1.5vh;
}
.div_close[data-v-09972ea4] {
    width: 92%;
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    /*background-color: red;*/
}
.im_close[data-v-09972ea4] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-self: flex-end;
    -ms-flex-item-align: end;
        align-self: flex-end;
    width: 30px;
    height: 30px;
}
.tx_title[data-v-09972ea4], .tx_title2[data-v-09972ea4], .tx_title2_card[data-v-09972ea4] {
    font-size: 16pt;
    font-weight: bold;
    /*background-color: orange;*/
    display: block;
    text-align:center;
    /*margin-left: 15vw;*/
    /*margin-right: 15vw;*/
}
.tx_title2[data-v-09972ea4] {
    margin-top: 3vw;
}
.tx_title2_card[data-v-09972ea4] {
    margin-top: 2vh;
    /*background-color: red;*/
}
.tx_timer[data-v-09972ea4], .tx_timerh[data-v-09972ea4], .tx_timerhb[data-v-09972ea4] {
    font-size: 13pt;
    /*font-weight: bold;*/
    /*font-style: italic;*/
    /*font-family: Impact;*/
    font-family: FocoI;
    /*font-family: Verdana;*/
    /*color: #e8842f;*/
    /*color: #e6512c;*/
    /*color: #664df4;*/
    color: white;
    /*background-color: red;*/
    /*text-shadow: -1.8px 0 black, 0 1.8px black, 1.8px 0 black, 0 -1.8px black;*/
    text-shadow: 1px 1px 0 #000,
    -1px 1px 0 #000,
    1px -1px 0 #000,
    -1px -1px 0 #000,
    0px 1px 0 #000,
    0px -1px 0 #000,
    -1px 0px 0 #000,
    1px 0px 0 #000,
    1.9px 1.9px 0 #000,
    -1.9px 1.9px 0 #000,
    1.9px -1.9px 0 #000,
    -1.9px -1.9px 0 #000,
    0px 1.9px 0 #000,
    0px -1.9px 0 #000,
    -1.9px 0px 0 #000,
    1.9px 0px 0 #000,
    1px 1.9px 0 #000,
    -1px 1.9px 0 #000,
    1px -1.9px 0 #000,
    -1px -1.9px 0 #000,
    1.9px 1px 0 #000,
    -1.9px 1px 0 #000,
    1.9px -1px 0 #000,
    -1.9px -1px 0 #000;
}
.tx_timerh[data-v-09972ea4], .tx_timerhb[data-v-09972ea4] {
    font-family: FocoB;
    font-style: normal;
    display:inline;
}
.tx_timerhb[data-v-09972ea4] {
    font-family: RamaE;
    font-size: 23pt;
}
.tx_timerh[data-v-09972ea4] {
    font-size: 18pt;
    margin-bottom: 2px;
}
.tx_lateral[data-v-09972ea4], .tx_lateral_video[data-v-09972ea4] {
    /*background-color: red;*/
    font-size: 15pt;
}
.im_lock[data-v-09972ea4], .im_video[data-v-09972ea4], .im_lock_d[data-v-09972ea4], .im_regula_d[data-v-09972ea4] {
    -o-object-fit: cover;
       object-fit: cover;
    width: 2vh;
    /*display: flex;*/
    margin-right: 1vh;
    -webkit-filter: brightness(0.5);
            filter: brightness(0.5);
    /*background-color: red;*/
}
.im_regula_d[data-v-09972ea4] {
    width: 2.5vh;
}
.im_lock_d[data-v-09972ea4] {
    width: 1.5vh;
}
.im_video[data-v-09972ea4] {
    width: 2.8vh;
}
.tx_lateral_lock[data-v-09972ea4], .tx_lateral_lock_d[data-v-09972ea4] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*padding-top: 1px;*/
    /*background-color: yellow;*/
    font-size: 15pt;
}
.tx_lateral_lock_d[data-v-09972ea4] {
    font-size: 13pt;
}
.div_lock[data-v-09972ea4], .div_video[data-v-09972ea4] {
    /*background-color: blue;*/
    margin-top: 3vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    vertical-align: center;
}
.div_video[data-v-09972ea4] {
    margin-top: 0;
    margin-bottom: 2vh;
}
.div_lock[data-v-09972ea4]:hover, .div_video[data-v-09972ea4]:hover {
    background-color: #ffcf72;
}
.tx_lateral_video[data-v-09972ea4] {
    width: 100%;
    display: block;
    margin-bottom: 2vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.tx_lateral_video[data-v-09972ea4]:hover {
    background-color: #ffcf72;
}
.tx_lateral_s[data-v-09972ea4], .tx_lateral_sb[data-v-09972ea4] {
    display: inline-block;
    font-size: 12pt;
}
.tx_lateral_sb[data-v-09972ea4] {
    font-weight: bold;
}
.tx_aviso[data-v-09972ea4], .tx_aviso_m[data-v-09972ea4] {
    font-weight: bold;
    font-size: 10pt;
    /*color: white;*/
    /*text-shadow: -1.8px 0 black, 0 1.8px black, 1.8px 0 black, 0 -1.8px black;*/
}
.tx_aviso[data-v-09972ea4] {
    color: white;
    /*text-shadow: -1.8px 0 black, 0 1.8px black, 1.8px 0 black, 0 -1.8px black;*/
    text-shadow: 1px 1px 0 #000,
    -1px 1px 0 #000,
    1px -1px 0 #000,
    -1px -1px 0 #000,
    0px 1px 0 #000,
    0px -1px 0 #000,
    -1px 0px 0 #000,
    1px 0px 0 #000,
    1px 1px 0 #000,
    -1px 1px 0 #000,
    1px -1px 0 #000,
    -1px -1px 0 #000,
    0px 1px 0 #000,
    0px -1px 0 #000,
    -1px 0px 0 #000,
    1px 0px 0 #000,
    1px 1px 0 #000,
    -1px 1px 0 #000,
    1px -1px 0 #000,
    -1px -1px 0 #000,
    1px 1px 0 #000,
    -1px 1px 0 #000,
    1px -1px 0 #000,
    -1px -1px 0 #000;
}
.tx_aviso_m[data-v-09972ea4] {
    font-size: 8pt;
}
.tx_oferta[data-v-09972ea4]  {
    font-size: 10pt;
}
.tx_oferta_card[data-v-09972ea4]  {
    /*font-size: 10pt;*/
    font-size: 1.5vh;
    max-height: 3.9vh;
    overflow: hidden;
    text-align: justify;
    padding-right: 2vw;
}
.tx_oferta_estab[data-v-09972ea4] {
    font-weight: bold;
    font-size: 11pt;
}
.tx_oferta_title[data-v-09972ea4] {
    font-weight: bold;
    font-size: 1.8vh;
    /*font-size: 11pt;*/
    max-height: 4.5vh;
}
.tx_oferta_central[data-v-09972ea4] {
    font-size: 10pt;
    text-align: justify;
    display: block;
}
.tx_oferta_central_estab[data-v-09972ea4] {
    font-size: 13pt;
    font-weight: bold;
}
.tx_oferta1[data-v-09972ea4], .tx_oferta1_show[data-v-09972ea4] {
    /*font-size: 10pt;*/
    font-size: 1.8vh;
    font-weight: bold;
    /*background-color: purple;*/
}
.tx_oferta1_show[data-v-09972ea4] {
    font-size: 13pt;
}
.tx_oferta2[data-v-09972ea4] {
    font-size: 13pt;
    font-weight: bold;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
}
.tx_oferta1_central[data-v-09972ea4] {
    font-size: 13pt;
    font-weight: bold;
}
.tx_oferta2_central[data-v-09972ea4] {
    font-size: 16pt;
    font-weight: bold;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
}
.tx_oferta_per[data-v-09972ea4], .tx_oferta_per_central[data-v-09972ea4] {
    font-size: 10pt;
    font-weight: bold;
    color: darkgreen;
    margin-left: 10px;
}
.tx_oferta_per_central[data-v-09972ea4] {
    font-size: 13pt;
}
.tx_oferta_esg[data-v-09972ea4] {
    font-size: 10pt;
    font-weight: bold;
    color: darkred;
}
.tx_oferta_esg_central[data-v-09972ea4] {
    font-size: 13pt;
    font-weight: bold;
    color: darkred;
}
.tx_regula[data-v-09972ea4] {
    text-align: justify;
    font-size: 13pt;
    font-weight: bold;
    /*color: darkred;*/
    margin-bottom: 2vh;
}
.div_regula[data-v-09972ea4] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 2vw;
    padding-right: 5vw;
    padding-left: 5vw;
}
.search0[data-v-09972ea4] {
    text-align: left;
    width: 100%;
}
.search_lupa[data-v-09972ea4], .search_lupa_cardapio[data-v-09972ea4] {
    width: 25px;
    height: 25px;
    margin-left: 2.5%;
    margin-bottom: -28px;
    position: relative;
}
.search_lupa_cardapio[data-v-09972ea4] {
    margin-bottom: unset;
    margin-right: 2.5%;
}
.search1[data-v-09972ea4] {
    background-image: url('/static/imgs/bg_search01m2.png');
    background-color: var(--bg-header-color);
    background-repeat: no-repeat;
    /*background-size: contain;*/
    background-size: 91% 30px;
    border: none;
    outline:none;
    position: center;
    padding-left: 10%;
    width: 100%;
    height: 30px;
    font-size: 10pt;
    text-align: left;
}
.search_cardapio[data-v-09972ea4] {
    /*background-size: contain;*/
    border: none;
    outline:none;
    position: center;
    padding-left: 2%;
    width: 100%;
    height: 30px;
    font-size: 2vh;
    text-align: left;
    background-color: transparent;
}
.im_menu1[data-v-09972ea4], .im_menu2[data-v-09972ea4] {
    height: 35px;
    vertical-align: middle;
    margin-left: 5px;
    top: 0;
    bottom: 0;
    /*background-color: red;*/
    margin-bottom: auto;
}
.im_menu1[data-v-09972ea4] {
    top: 0;
    left: 0;
    position: relative;
    margin-left: 0px;
}
.im_share[data-v-09972ea4] {
    background-color:royalblue;
    /* Tamanho tem que ser proporcional ao tamanho do div_oferta que eh 28vh*/
    width: 4vh;
    height: 4vh;
}
.im_share_m[data-v-09972ea4] {
    background-color:royalblue;
    /* Tamanho tem que ser proporcional ao tamanho do div_oferta_m que eh 70vmin*/
    width: 4vh;
    height: 4vh;
}
a[data-v-09972ea4]:hover, a[data-v-09972ea4]:visited, a[data-v-09972ea4]:link, a[data-v-09972ea4]:active
{
    text-decoration: none;
    color: unset;
}
.div_oferta_c_compra[data-v-09972ea4] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    text-align: left;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    /*background-color: yellow;*/
}
.div_oferta_compra[data-v-09972ea4] {
    background-color: white;
    position: relative;
    margin-right: 1vw;
    margin-left: 1vw;
    margin-top: 1vw;
    /*display: inline-block;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    /*background-color: white;*/
    border-color: #CCC;
    border-width: 1px;
    border-style: solid;
    padding: 2vw;
    width: 30vw;
    /*height: 38vh;*/
    min-height: 25vh;
    text-align: left;
}
@media (min-width:320px) {
    /* smartphones, iPhone, portrait 480x320 phones */
.div_oferta_compra[data-v-09972ea4] {
        width: 90vw;
}
}
@media (min-width:481px)  {
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
.div_oferta_compra[data-v-09972ea4] {
        width: 90vw;
}
}
@media (min-width:521px) {
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
.div_oferta_compra[data-v-09972ea4] {
        width: 40vw; /* 2 lojas*!*/
}
}
@media (min-width:641px) {
    /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
.div_oferta_compra[data-v-09972ea4] {
        /*width: 40vw; !* 2 lojas*!*/
        width: 27vw; /* 3 lojas */
}
}
@media (min-width:861px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
.div_oferta_compra[data-v-09972ea4] {
        /*width: 27vw; !* 3 lojas *!*/
        width: 17vw; /* 4 lojas */
}
.rootviewm_compra[data-v-09972ea4] {
        width: 40%;
}
.header_p1_c_m[data-v-09972ea4] {
        width: 40%;
}
.ofertaView_card[data-v-09972ea4] {
        width: 40%;
}
.overlay_oferta_card[data-v-09972ea4] {
        width: 40%;
}
.im_thumb[data-v-09972ea4] {
        margin-left: -1.5vw;
        /*background-color: red;*/
}
}
@media (min-width:1125px) {
    /* big landscape tablets, laptops, and desktops */
.div_oferta_compra[data-v-09972ea4] {
        /*width: 17vw; !* 4 lojas *!*/
        width: 13vw; /* 5 lojas */
}
}
@media (min-width:1281px) {
    /* hi-res laptops and desktops */
.div_oferta_compra[data-v-09972ea4] {
        /*width: 16vw; !* 4 lojas *!*/
        width: 10vw; /* 6 lojas */
}
}
[data-v-09972ea4]::-webkit-scrollbar {
    display: none;
}

h1[data-v-63517c05], h2[data-v-63517c05] {
    font-weight: normal;
    font-size: 20pt;
}
.search[data-v-63517c05] {
    background-image: url('/static/imgs/bg_search01.png');
    background-color: transparent;
    background-repeat: no-repeat;
    /*background-size: contain;*/
    background-size: 100% 28px;
    border: none;
    outline:none;
    /*position: center;*/
    /*position: relative;*/
    /*margin-top: -15px;*/
    padding-left: 50px;
    width: 100%;
    height: 28px;
    max-width: 662px;
}
.rootview[data-v-63517c05] {
    display: inline-block;
    /*background-color: red;*/
    /*background-color: lightgray;*/

    width: 100%;
    height: 100vh;
}
.rootviewm[data-v-63517c05] {
    display: inline-block;
    /*background-color: red;*/
    /*background-color: lightgray;*/

    width: 100%;
    height: 100vh;
}
.rootviewm_compra[data-v-63517c05] {
    display: inline-block;
    /*background-color: red;*/
    /*background-color: lightgray;*/

    width: 100%;
    height: auto;
    border-color: #CCC;
    border-width: 1px;
    border-style: solid;
    min-height: 100vh;
}
.header[data-v-63517c05] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    /*background-color: #ffe106;*/
    width: 100%;
    margin-bottom: 3vh;

    /*font-size: 0;*/
    /*height: 30vh;*/
}
.header_p1_c[data-v-63517c05], .header_p1_c_m[data-v-63517c05] {
    /*background-color: #ffe106;*/
    /*background-color: #fcd842;*/
    /*background-color: green;*/
    /*background-color: var(--bg-header-color);*/
    background-image: url('/static/imgs/bg_cabecalho.jpg');
    background-size: cover;

    position: fixed;
    z-index: 2;
    height: var(--size-header);
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.header_p1_c_m[data-v-63517c05] {
    top: 0;
    height: auto;
    /*background-color: green;*/
}
.header_p1[data-v-63517c05], .header_p1_m[data-v-63517c05] {
    /*background-color: darkred;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    width: 100%;
    height: var(--size-header);
    /*height: 100%;*/
}
.header_p1_m[data-v-63517c05] {
    height: var(--size-header-m);
}
.bodyview[data-v-63517c05] {
    display: inline-block;
    /*background-color: gray;*/
    /*background-color: green;*/
    width: 100%;
    height: auto;
    margin-bottom: 50px;
}
.side_right[data-v-63517c05], .side_left[data-v-63517c05] {
    /*background-color: var(--main-bg-color);*/
    /*background-color: #ffcf72;*/
    /*background-color: #fdd92c;*/
    /*background-color: #fdf7dc;*/
}
.side_left[data-v-63517c05], .side_left_m[data-v-63517c05] {
    /*background-color: yellow;*/
    width: 24vw;
    padding-left: 1vw;
    text-align: left;
    float: left;
    height: 100%;
}
.side_left[data-v-63517c05] {
    border-width: 0.1vw;
    border-color: darkgray;
    border-style: none solid none none;
}
.side_left_m[data-v-63517c05] {
    margin-top: 2vh;
    width: 100%;
    padding-left: 2vw;
}
.side_right[data-v-63517c05] {
    /*background-color: darkred;*/
    width: 75vw;
    height: auto;
    margin-left: 25vw;
    border-width: 0.1vw;
    border-color: darkgray;
    border-style: none none none solid;
}
.side_left_h[data-v-63517c05], .side_middle_h[data-v-63517c05], .side_right_h[data-v-63517c05], .side_right_h_m[data-v-63517c05] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*flex: 1 1 0;*/
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    /*flex-shrink: 1;*/
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
}
.side_left_h[data-v-63517c05] {
    /*background-color: blue;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*align-items: center;*/
    /*margin-left: 1.5vw;*/
}
.side_middle_h[data-v-63517c05] {
    /*background-color: pink;*/
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-flex: 1.6;
        -ms-flex-positive: 1.6;
            flex-grow: 1.6;
}
.side_right_h[data-v-63517c05], .side_right_h_m[data-v-63517c05] {
    /*background-color: yellow;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    /*justify-content: space-between;*/
}
.side_right_h_m[data-v-63517c05] {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.div_share[data-v-63517c05], .div_share_central[data-v-63517c05], .div_share_m[data-v-63517c05], div_share_central_m[data-v-63517c05] {
    position: absolute;
}
.div_share[data-v-63517c05] {
    /* proporcional ao .div_oferta que tem tamanho 28vh*/
    margin-left: 16vh;
}
.div_share_central[data-v-63517c05] {
    /* proporcional ao .div_oferta_central que tem tam 50%*/
    margin-left: 43%;
}
.div_share_m[data-v-63517c05] {
    /* proporcional ao .div_oferta_m que tem tamanho 70vmin*/
    margin-left: 46vmin;
}
.div_share_central_m[data-v-63517c05] {
    /* proporcional ao .div_oferta_central_m que tem tamanho 100%*/
    margin-left: 70%;
}
.div_cats[data-v-63517c05] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    /*background-color: red;*/
    margin-left: 0.5vw;
}
.div_subcats[data-v-63517c05] {
    margin-top: 0.75vh;
    margin-bottom: 0.75vh;
    /*background-color: purple;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.div_cats[data-v-63517c05]:hover {
    background-color: #ffcf72;
    /*background-color: #fdd92c;*/
    /*background-color: #ffe106;*/
}
.div_oferta[data-v-63517c05], .div_oferta_m[data-v-63517c05], .div_oferta_m_card[data-v-63517c05] {
    /*position: relative;*/
    margin-right: 3vh;
    margin-left: 3vh;
    margin-top: 3vh;
    display: inline-block;
    /*background-color: red;*/
    background-color: white;
    -webkit-box-shadow: 3px 3px 3px lightgray;
            box-shadow: 3px 3px 3px lightgray;
    padding: 2vh;
    width: 28vh;
    /*height: 38vh;*/
    min-height: 38vh;
    text-align: left;
}
.div_oferta_m[data-v-63517c05], .div_oferta_m_card[data-v-63517c05] {
    /*background-color: yellow;*/
    width: 70vmin;
    /*width: 60vmin;*/
}
.div_oferta_m_card[data-v-63517c05] {
    background-color: white;
    /*background-color: pink;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    min-height: unset;
    min-height: 9vh;
    height: auto;
    max-height: 14vh;
    /*align-items: stretch;*/
    /*min-width: 96vw;*/
    width: 96%;
    padding: 2%;
    position: relative;

    margin-right: 0;
    margin-left: 0;
    margin-top: 0;
    border: 0px;
    border-top: 1px;
    border-color: #cccccc;
    border-style: solid;
    margin: 0;
    /*padding: 2vw;*/
}
.div_oferta_c[data-v-63517c05], .div_oferta_c_m[data-v-63517c05] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    text-align: left;
}
.div_oferta_c_m[data-v-63517c05] {
    /*background-color: red;*/
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
}
.div_oferta_compra[data-v-63517c05] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    text-align: left;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    /*background-color: yellow;*/
}
.div_timer[data-v-63517c05] {
    /*background-image: url('/static/imgs/balloon2.png');*/
    /*background-repeat: no-repeat;*/
    /*background-size: 90% 120%;*/
    /*background-position: 50% 90%;*/
    /*height: 100%;*/
    /*flex-grow: 1;*/
    /*justify-content: center;*/

    /*background-color: #6b35c4;*/
    /*background-color: white;*/
    /*align-self: center;*/
    /*justify-self: center;*/
    /*align-items: center;*/
    /*align-content: center;*/
    /*border-color: #6b35c4;*/
    /*border-width: 3px;*/
    /*border-style: solid;*/
    /*border-radius: 15px;*/
    /*padding: 5px;*/
    /*margin-left: 15%;*/

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;

    /*margin-top: 1vw;*/
}
.div_timer_m[data-v-63517c05] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.div_social[data-v-63517c05], .div_social2[data-v-63517c05] {
    /*background-color: blue;*/
    margin-top: 5vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*justify-content: center;*/
}
.div_social2[data-v-63517c05] {
    /*background-color: purple;*/
    /*top: 7vh;*/
}
.div_app[data-v-63517c05] {
    /*background-color: blue;*/
    margin-top: 2vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*justify-content: center;*/
}
.div_search[data-v-63517c05] {
    /*height: 275px;*/
    /*max-height: 275px;*/
    text-align: center;
    /*background-color: red;*/
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    /*margin-bottom: -28px;*/
    margin-top: -15px;
    height: 0px;
    /*justify-self: center;*/
    /*align-self: center;*/
    /*transform: translateY(15px);*/
}
.div_oferta_central[data-v-63517c05], .div_oferta_central_m[data-v-63517c05] {
    /*background-color: red;*/
    background-color: #fff;
    -webkit-box-shadow: 3px 3px 3px black;
            box-shadow: 3px 3px 3px black;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 50%;
    max-height: 100vh;
    height: auto;
    overflow-x: scroll;
    padding-top: 1%;
    padding-bottom: 2%;
    padding-right: 2%;
    padding-left: 2%;
    margin-right: 2%;
    margin-left: 2%;
    text-align: left;
    z-index: 1001;
}
.div_oferta_central_m[data-v-63517c05] {
    width: 100%;
}
.ofertaView[data-v-63517c05], .ofertaView_card[data-v-63517c05] {
    /*background-color: red;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: fixed;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow-x: auto;
    z-index: 3;
    /*max-height: 100vh;*/
}
.overlay_oferta[data-v-63517c05], .overlay_oferta_card[data-v-63517c05] {
    position: fixed;
    top: 0;
    opacity:0.8;
    background-color: black;
    width: 100vw;
    height: 100vh;
    z-index: 2;
}
.im_loading[data-v-63517c05], .im_loading_end[data-v-63517c05] {
    width: 80px;
    height: 80px;
    display: inline-block;
}
.im_loading_end[data-v-63517c05] {
    width: 50px;
    height: 50px;
}
.im_thumb[data-v-63517c05], .im_thumb_central[data-v-63517c05], .im_thumb_card[data-v-63517c05] {
    text-align: center;
    /*background-color: green;*/
    height: 25vh;
    max-width: 23vh;
    -o-object-fit: contain;
       object-fit: contain;
}
.im_thumb[data-v-63517c05] {
    color-scheme: only light;
}
.im_thumb_card[data-v-63517c05] {
    position: relative;
    max-width: unset;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 6px;
}
.im_thumb_m[data-v-63517c05]{
    text-align: center;
    /*background-color: green;*/
    /*height: 25vh;*/
    width: 90%;
    /*max-width: 23vh;*/
    -o-object-fit: contain;
       object-fit: contain;
}
.im_thumb_central[data-v-63517c05] {
    height: 55vh;
    max-width: 90%;
}
.im_thumb_central_m[data-v-63517c05] {
    width: 100%;
    text-align: center;
    max-height: 70vmax;
    -o-object-fit: contain;
       object-fit: contain;
    /*background-color: red;*/
}
.im_thumb_mini_central[data-v-63517c05] {
    border-width: 2px;
    border-bottom-color: black;
    border-style: solid;
    width: 50px;
    height: 50px;
    -o-object-fit: contain;
       object-fit: contain;
}
.im_cat[data-v-63517c05], .im_cat_selected[data-v-63517c05] {
    text-align: center;
    /*background-color: red;*/
    width: 2.5vh;
    margin-right: 1vh;
    -o-object-fit: contain;
       object-fit: contain;
    -webkit-filter: brightness(0.25);
            filter: brightness(0.25);
}
.im_cat_selected[data-v-63517c05] {
    /*filter: contrast(0);*/
    -webkit-filter: brightness(0%);
            filter: brightness(0%);
}
.im_logo[data-v-63517c05] {
    margin-left: 3vh;
    height: 11vh;
    /*height: 6.2vw;*/
}
.im_ttt[data-v-63517c05] {
    width: 25vw;
    width: 44vh;
    -ms-flex-item-align: center;
        align-self: center;
}
.im_ttt2[data-v-63517c05] {
    height: 12vh;
    -ms-flex-item-align: start;
        align-self: flex-start;
}
.im_ttt_m[data-v-63517c05] {
    width: 30vh;
    -ms-flex-item-align: center;
        align-self: center;
    -o-object-fit: contain;
       object-fit: contain;
}
.im_social[data-v-63517c05] {
    height: 5vh;
    width: 5vh;
    margin-right: 1vh;
    margin-left: 1vh;
}
.im_app[data-v-63517c05] {
    height: 8vh;
    width: 8vh;
    margin-right: 1vh;
    margin-left: 1vh;
}
.im_banner[data-v-63517c05], .im_banner_m[data-v-63517c05], .im_banner_m_app[data-v-63517c05] {
    /*top: 0px;*/
    margin-top: var(--size-header);
    width: 100%;
    /*height: 100%;*/
}
.im_banner_m[data-v-63517c05] {
    margin-top: calc(var(--size-header-m) - 1px);
    /*margin-top: var(--size-header-m);*/
}
.im_banner_m_app[data-v-63517c05] {
    margin-top: 0;
}
.im_balloom[data-v-63517c05] {
    position: fixed;
    /*margin-top: 25%;*/
    -ms-flex-item-align: center;
        align-self: center;
    /*width: 85%;*/
    z-index: 2;
}
.im_menu[data-v-63517c05] {
    width: 4vh;
    height: 4vh;
    margin-left: 1vh;
    /*margin-top: 1vh;*/
    vertical-align: middle;
    -o-object-fit: contain;
}
#dismiss[data-v-63517c05] {
    margin-top: 1.4vh;
}
.im_profile[data-v-63517c05], .im_profile_l[data-v-63517c05] {
    /*background-color: red;*/
    position: fixed;
    right: 2px;
    top: 2px;
    height: 5vh;
    width: 5vh;
}
.im_profile_l[data-v-63517c05] {
    top: 0px;
    margin-top: 1.5vh;
}
.div_close[data-v-63517c05] {
    width: 92%;
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    /*background-color: red;*/
}
.im_close[data-v-63517c05] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-self: flex-end;
    -ms-flex-item-align: end;
        align-self: flex-end;
    width: 30px;
    height: 30px;
}
.tx_title[data-v-63517c05], .tx_title2[data-v-63517c05], .tx_title2_card[data-v-63517c05] {
    font-size: 16pt;
    font-weight: bold;
    /*background-color: orange;*/
    display: block;
    text-align:center;
    /*margin-left: 15vw;*/
    /*margin-right: 15vw;*/
}
.tx_title2[data-v-63517c05] {
    margin-top: 3vw;
}
.tx_title2_card[data-v-63517c05] {
    margin-top: 2vh;
    /*background-color: red;*/
}
.tx_timer[data-v-63517c05], .tx_timerh[data-v-63517c05], .tx_timerhb[data-v-63517c05] {
    font-size: 13pt;
    /*font-weight: bold;*/
    /*font-style: italic;*/
    /*font-family: Impact;*/
    font-family: FocoI;
    /*font-family: Verdana;*/
    /*color: #e8842f;*/
    /*color: #e6512c;*/
    /*color: #664df4;*/
    color: white;
    /*background-color: red;*/
    /*text-shadow: -1.8px 0 black, 0 1.8px black, 1.8px 0 black, 0 -1.8px black;*/
    text-shadow: 1px 1px 0 #000,
    -1px 1px 0 #000,
    1px -1px 0 #000,
    -1px -1px 0 #000,
    0px 1px 0 #000,
    0px -1px 0 #000,
    -1px 0px 0 #000,
    1px 0px 0 #000,
    1.9px 1.9px 0 #000,
    -1.9px 1.9px 0 #000,
    1.9px -1.9px 0 #000,
    -1.9px -1.9px 0 #000,
    0px 1.9px 0 #000,
    0px -1.9px 0 #000,
    -1.9px 0px 0 #000,
    1.9px 0px 0 #000,
    1px 1.9px 0 #000,
    -1px 1.9px 0 #000,
    1px -1.9px 0 #000,
    -1px -1.9px 0 #000,
    1.9px 1px 0 #000,
    -1.9px 1px 0 #000,
    1.9px -1px 0 #000,
    -1.9px -1px 0 #000;
}
.tx_timerh[data-v-63517c05], .tx_timerhb[data-v-63517c05] {
    font-family: FocoB;
    font-style: normal;
    display:inline;
}
.tx_timerhb[data-v-63517c05] {
    font-family: RamaE;
    font-size: 23pt;
}
.tx_timerh[data-v-63517c05] {
    font-size: 18pt;
    margin-bottom: 2px;
}
.tx_lateral[data-v-63517c05], .tx_lateral_video[data-v-63517c05] {
    /*background-color: red;*/
    font-size: 15pt;
}
.im_lock[data-v-63517c05], .im_video[data-v-63517c05], .im_lock_d[data-v-63517c05], .im_regula_d[data-v-63517c05] {
    -o-object-fit: cover;
       object-fit: cover;
    width: 2vh;
    /*display: flex;*/
    margin-right: 1vh;
    -webkit-filter: brightness(0.5);
            filter: brightness(0.5);
    /*background-color: red;*/
}
.im_regula_d[data-v-63517c05] {
    width: 2.5vh;
}
.im_lock_d[data-v-63517c05] {
    width: 1.5vh;
}
.im_video[data-v-63517c05] {
    width: 2.8vh;
}
.tx_lateral_lock[data-v-63517c05], .tx_lateral_lock_d[data-v-63517c05] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*padding-top: 1px;*/
    /*background-color: yellow;*/
    font-size: 15pt;
}
.tx_lateral_lock_d[data-v-63517c05] {
    font-size: 13pt;
}
.div_lock[data-v-63517c05], .div_video[data-v-63517c05] {
    /*background-color: blue;*/
    margin-top: 3vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    vertical-align: center;
}
.div_video[data-v-63517c05] {
    margin-top: 0;
    margin-bottom: 2vh;
}
.div_lock[data-v-63517c05]:hover, .div_video[data-v-63517c05]:hover {
    background-color: #ffcf72;
}
.tx_lateral_video[data-v-63517c05] {
    width: 100%;
    display: block;
    margin-bottom: 2vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.tx_lateral_video[data-v-63517c05]:hover {
    background-color: #ffcf72;
}
.tx_lateral_s[data-v-63517c05], .tx_lateral_sb[data-v-63517c05] {
    display: inline-block;
    font-size: 12pt;
}
.tx_lateral_sb[data-v-63517c05] {
    font-weight: bold;
}
.tx_aviso[data-v-63517c05], .tx_aviso_m[data-v-63517c05] {
    font-weight: bold;
    font-size: 10pt;
    /*color: white;*/
    /*text-shadow: -1.8px 0 black, 0 1.8px black, 1.8px 0 black, 0 -1.8px black;*/
}
.tx_aviso[data-v-63517c05] {
    color: white;
    /*text-shadow: -1.8px 0 black, 0 1.8px black, 1.8px 0 black, 0 -1.8px black;*/
    text-shadow: 1px 1px 0 #000,
    -1px 1px 0 #000,
    1px -1px 0 #000,
    -1px -1px 0 #000,
    0px 1px 0 #000,
    0px -1px 0 #000,
    -1px 0px 0 #000,
    1px 0px 0 #000,
    1px 1px 0 #000,
    -1px 1px 0 #000,
    1px -1px 0 #000,
    -1px -1px 0 #000,
    0px 1px 0 #000,
    0px -1px 0 #000,
    -1px 0px 0 #000,
    1px 0px 0 #000,
    1px 1px 0 #000,
    -1px 1px 0 #000,
    1px -1px 0 #000,
    -1px -1px 0 #000,
    1px 1px 0 #000,
    -1px 1px 0 #000,
    1px -1px 0 #000,
    -1px -1px 0 #000;
}
.tx_aviso_m[data-v-63517c05] {
    font-size: 8pt;
}
.tx_oferta[data-v-63517c05]  {
    font-size: 10pt;
}
.tx_oferta_card[data-v-63517c05]  {
    /*font-size: 10pt;*/
    font-size: 1.5vh;
    max-height: 3.9vh;
    overflow: hidden;
    text-align: justify;
    padding-right: 2vw;
}
.tx_oferta_estab[data-v-63517c05] {
    font-weight: bold;
    font-size: 11pt;
}
.tx_oferta_title[data-v-63517c05] {
    font-weight: bold;
    font-size: 1.8vh;
    /*font-size: 11pt;*/
    max-height: 4.5vh;
}
.tx_oferta_central[data-v-63517c05] {
    font-size: 10pt;
    text-align: justify;
    display: block;
}
.tx_oferta_central_estab[data-v-63517c05] {
    font-size: 13pt;
    font-weight: bold;
}
.tx_oferta1[data-v-63517c05], .tx_oferta1_show[data-v-63517c05] {
    /*font-size: 10pt;*/
    font-size: 1.8vh;
    font-weight: bold;
    /*background-color: purple;*/
}
.tx_oferta1_show[data-v-63517c05] {
    font-size: 13pt;
}
.tx_oferta2[data-v-63517c05] {
    font-size: 13pt;
    font-weight: bold;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
}
.tx_oferta1_central[data-v-63517c05] {
    font-size: 13pt;
    font-weight: bold;
}
.tx_oferta2_central[data-v-63517c05] {
    font-size: 16pt;
    font-weight: bold;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
}
.tx_oferta_per[data-v-63517c05], .tx_oferta_per_central[data-v-63517c05] {
    font-size: 10pt;
    font-weight: bold;
    color: darkgreen;
    margin-left: 10px;
}
.tx_oferta_per_central[data-v-63517c05] {
    font-size: 13pt;
}
.tx_oferta_esg[data-v-63517c05] {
    font-size: 10pt;
    font-weight: bold;
    color: darkred;
}
.tx_oferta_esg_central[data-v-63517c05] {
    font-size: 13pt;
    font-weight: bold;
    color: darkred;
}
.tx_regula[data-v-63517c05] {
    text-align: justify;
    font-size: 13pt;
    font-weight: bold;
    /*color: darkred;*/
    margin-bottom: 2vh;
}
.div_regula[data-v-63517c05] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 2vw;
    padding-right: 5vw;
    padding-left: 5vw;
}
.search0[data-v-63517c05] {
    text-align: left;
    width: 100%;
}
.search_lupa[data-v-63517c05], .search_lupa_cardapio[data-v-63517c05] {
    width: 25px;
    height: 25px;
    margin-left: 2.5%;
    margin-bottom: -28px;
    position: relative;
}
.search_lupa_cardapio[data-v-63517c05] {
    margin-bottom: unset;
    margin-right: 2.5%;
}
.search1[data-v-63517c05] {
    background-image: url('/static/imgs/bg_search01m2.png');
    background-color: var(--bg-header-color);
    background-repeat: no-repeat;
    /*background-size: contain;*/
    background-size: 91% 30px;
    border: none;
    outline:none;
    position: center;
    padding-left: 10%;
    width: 100%;
    height: 30px;
    font-size: 10pt;
    text-align: left;
}
.search_cardapio[data-v-63517c05] {
    /*background-size: contain;*/
    border: none;
    outline:none;
    position: center;
    padding-left: 2%;
    width: 100%;
    height: 30px;
    font-size: 2vh;
    text-align: left;
    background-color: transparent;
}
.im_menu1[data-v-63517c05], .im_menu2[data-v-63517c05] {
    height: 35px;
    vertical-align: middle;
    margin-left: 5px;
    top: 0;
    bottom: 0;
    /*background-color: red;*/
    margin-bottom: auto;
}
.im_menu1[data-v-63517c05] {
    top: 0;
    left: 0;
    position: relative;
    margin-left: 0px;
}
.im_share[data-v-63517c05] {
    background-color:royalblue;
    /* Tamanho tem que ser proporcional ao tamanho do div_oferta que eh 28vh*/
    width: 4vh;
    height: 4vh;
}
.im_share_m[data-v-63517c05] {
    background-color:royalblue;
    /* Tamanho tem que ser proporcional ao tamanho do div_oferta_m que eh 70vmin*/
    width: 4vh;
    height: 4vh;
}
a[data-v-63517c05]:hover, a[data-v-63517c05]:visited, a[data-v-63517c05]:link, a[data-v-63517c05]:active
{
    text-decoration: none;
    color: unset;
}
.div_oferta_c_compra[data-v-63517c05] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    text-align: left;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    /*background-color: yellow;*/
}
.div_oferta_compra[data-v-63517c05] {
    background-color: white;
    position: relative;
    margin-right: 1vw;
    margin-left: 1vw;
    margin-top: 1vw;
    /*display: inline-block;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    /*background-color: white;*/
    border-color: #CCC;
    border-width: 1px;
    border-style: solid;
    padding: 2vw;
    width: 30vw;
    /*height: 38vh;*/
    min-height: 25vh;
    text-align: left;
}
@media (min-width:320px) {
    /* smartphones, iPhone, portrait 480x320 phones */
.div_oferta_compra[data-v-63517c05] {
        width: 90vw;
}
}
@media (min-width:481px)  {
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
.div_oferta_compra[data-v-63517c05] {
        width: 90vw;
}
}
@media (min-width:521px) {
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
.div_oferta_compra[data-v-63517c05] {
        width: 40vw; /* 2 lojas*!*/
}
}
@media (min-width:641px) {
    /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
.div_oferta_compra[data-v-63517c05] {
        /*width: 40vw; !* 2 lojas*!*/
        width: 27vw; /* 3 lojas */
}
}
@media (min-width:861px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
.div_oferta_compra[data-v-63517c05] {
        /*width: 27vw; !* 3 lojas *!*/
        width: 17vw; /* 4 lojas */
}
.rootviewm_compra[data-v-63517c05] {
        width: 40%;
}
.header_p1_c_m[data-v-63517c05] {
        width: 40%;
}
.ofertaView_card[data-v-63517c05] {
        width: 40%;
}
.overlay_oferta_card[data-v-63517c05] {
        width: 40%;
}
.im_thumb[data-v-63517c05] {
        margin-left: -1.5vw;
        /*background-color: red;*/
}
}
@media (min-width:1125px) {
    /* big landscape tablets, laptops, and desktops */
.div_oferta_compra[data-v-63517c05] {
        /*width: 17vw; !* 4 lojas *!*/
        width: 13vw; /* 5 lojas */
}
}
@media (min-width:1281px) {
    /* hi-res laptops and desktops */
.div_oferta_compra[data-v-63517c05] {
        /*width: 16vw; !* 4 lojas *!*/
        width: 10vw; /* 6 lojas */
}
}
[data-v-63517c05]::-webkit-scrollbar {
    display: none;
}

.roothome[data-v-c2e14e50] {
    background-color: #E4E4E4;
}
.wrapper[data-v-c2e14e50] {
    /*background-color: red;*/
}
.head0[data-v-c2e14e50] {
    position: fixed;
    top: 0;
    z-index: 1;
}
.head0_2[data-v-c2e14e50] {
    /*background-color: pink;*/
    background-image: url("/static/imgs/bg_topbar.png");
    background-size: cover;
    position: relative;
    height: 60px;
    width: 100vw;
    font-size: 0;
}
.head1[data-v-c2e14e50] {
    height: 100%;
    margin:auto;
    display: inline-block;
    position: relative;
}
.head1[data-v-c2e14e50] {
    width: 18%;
    text-align: left;
}
.im_menu1[data-v-c2e14e50], .im_menu2[data-v-c2e14e50] {
    height: 35px;
    vertical-align: middle;
    margin-left: 5px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
}
.im_menu1[data-v-c2e14e50] {
    top: 0;
    left: 0;
    position: relative;
    margin-left: 0px;
}
.im_menu2[data-v-c2e14e50] {
    position: absolute;
    margin-left: 5px;
}
#dismiss[data-v-c2e14e50] {
    top: 0;
    left: 0;
    margin-left: 5px;
    margin-top: 10px;
}


h1[data-v-c7966306], h2[data-v-c7966306] {
    font-weight: normal;
    font-size: 20pt;
}
.search[data-v-c7966306] {
    background-image: url('/static/imgs/bg_search01.png');
    background-color: transparent;
    background-repeat: no-repeat;
    /*background-size: contain;*/
    background-size: 100% 28px;
    border: none;
    outline:none;
    /*position: center;*/
    /*position: relative;*/
    /*margin-top: -15px;*/
    padding-left: 50px;
    width: 100%;
    height: 28px;
    max-width: 662px;
}
.rootview[data-v-c7966306] {
    display: inline-block;
    /*background-color: red;*/
    /*background-color: lightgray;*/

    width: 100%;
    height: 100vh;
}
.rootviewm[data-v-c7966306] {
    display: inline-block;
    /*background-color: red;*/
    /*background-color: lightgray;*/

    width: 100%;
    height: 100vh;
}
.rootviewm_compra[data-v-c7966306] {
    display: inline-block;
    /*background-color: red;*/
    /*background-color: lightgray;*/

    width: 100%;
    height: auto;
    border-color: #CCC;
    border-width: 1px;
    border-style: solid;
    min-height: 100vh;
}
.header[data-v-c7966306] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    /*background-color: #ffe106;*/
    width: 100%;
    margin-bottom: 3vh;

    /*font-size: 0;*/
    /*height: 30vh;*/
}
.header_p1_c[data-v-c7966306], .header_p1_c_m[data-v-c7966306] {
    /*background-color: #ffe106;*/
    /*background-color: #fcd842;*/
    /*background-color: green;*/
    /*background-color: var(--bg-header-color);*/
    background-image: url('/static/imgs/bg_cabecalho.jpg');
    background-size: cover;

    position: fixed;
    z-index: 2;
    height: var(--size-header);
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.header_p1_c_m[data-v-c7966306] {
    top: 0;
    height: auto;
    /*background-color: green;*/
}
.header_p1[data-v-c7966306], .header_p1_m[data-v-c7966306] {
    /*background-color: darkred;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    width: 100%;
    height: var(--size-header);
    /*height: 100%;*/
}
.header_p1_m[data-v-c7966306] {
    height: var(--size-header-m);
}
.bodyview[data-v-c7966306] {
    display: inline-block;
    /*background-color: gray;*/
    /*background-color: green;*/
    width: 100%;
    height: auto;
    margin-bottom: 50px;
}
.side_right[data-v-c7966306], .side_left[data-v-c7966306] {
    /*background-color: var(--main-bg-color);*/
    /*background-color: #ffcf72;*/
    /*background-color: #fdd92c;*/
    /*background-color: #fdf7dc;*/
}
.side_left[data-v-c7966306], .side_left_m[data-v-c7966306] {
    /*background-color: yellow;*/
    width: 24vw;
    padding-left: 1vw;
    text-align: left;
    float: left;
    height: 100%;
}
.side_left[data-v-c7966306] {
    border-width: 0.1vw;
    border-color: darkgray;
    border-style: none solid none none;
}
.side_left_m[data-v-c7966306] {
    margin-top: 2vh;
    width: 100%;
    padding-left: 2vw;
}
.side_right[data-v-c7966306] {
    /*background-color: darkred;*/
    width: 75vw;
    height: auto;
    margin-left: 25vw;
    border-width: 0.1vw;
    border-color: darkgray;
    border-style: none none none solid;
}
.side_left_h[data-v-c7966306], .side_middle_h[data-v-c7966306], .side_right_h[data-v-c7966306], .side_right_h_m[data-v-c7966306] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*flex: 1 1 0;*/
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    /*flex-shrink: 1;*/
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
}
.side_left_h[data-v-c7966306] {
    /*background-color: blue;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*align-items: center;*/
    /*margin-left: 1.5vw;*/
}
.side_middle_h[data-v-c7966306] {
    /*background-color: pink;*/
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-flex: 1.6;
        -ms-flex-positive: 1.6;
            flex-grow: 1.6;
}
.side_right_h[data-v-c7966306], .side_right_h_m[data-v-c7966306] {
    /*background-color: yellow;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    /*justify-content: space-between;*/
}
.side_right_h_m[data-v-c7966306] {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.div_share[data-v-c7966306], .div_share_central[data-v-c7966306], .div_share_m[data-v-c7966306], div_share_central_m[data-v-c7966306] {
    position: absolute;
}
.div_share[data-v-c7966306] {
    /* proporcional ao .div_oferta que tem tamanho 28vh*/
    margin-left: 16vh;
}
.div_share_central[data-v-c7966306] {
    /* proporcional ao .div_oferta_central que tem tam 50%*/
    margin-left: 43%;
}
.div_share_m[data-v-c7966306] {
    /* proporcional ao .div_oferta_m que tem tamanho 70vmin*/
    margin-left: 46vmin;
}
.div_share_central_m[data-v-c7966306] {
    /* proporcional ao .div_oferta_central_m que tem tamanho 100%*/
    margin-left: 70%;
}
.div_cats[data-v-c7966306] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    /*background-color: red;*/
    margin-left: 0.5vw;
}
.div_subcats[data-v-c7966306] {
    margin-top: 0.75vh;
    margin-bottom: 0.75vh;
    /*background-color: purple;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.div_cats[data-v-c7966306]:hover {
    background-color: #ffcf72;
    /*background-color: #fdd92c;*/
    /*background-color: #ffe106;*/
}
.div_oferta[data-v-c7966306], .div_oferta_m[data-v-c7966306], .div_oferta_m_card[data-v-c7966306] {
    /*position: relative;*/
    margin-right: 3vh;
    margin-left: 3vh;
    margin-top: 3vh;
    display: inline-block;
    /*background-color: red;*/
    background-color: white;
    -webkit-box-shadow: 3px 3px 3px lightgray;
            box-shadow: 3px 3px 3px lightgray;
    padding: 2vh;
    width: 28vh;
    /*height: 38vh;*/
    min-height: 38vh;
    text-align: left;
}
.div_oferta_m[data-v-c7966306], .div_oferta_m_card[data-v-c7966306] {
    /*background-color: yellow;*/
    width: 70vmin;
    /*width: 60vmin;*/
}
.div_oferta_m_card[data-v-c7966306] {
    background-color: white;
    /*background-color: pink;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    min-height: unset;
    min-height: 9vh;
    height: auto;
    max-height: 14vh;
    /*align-items: stretch;*/
    /*min-width: 96vw;*/
    width: 96%;
    padding: 2%;
    position: relative;

    margin-right: 0;
    margin-left: 0;
    margin-top: 0;
    border: 0px;
    border-top: 1px;
    border-color: #cccccc;
    border-style: solid;
    margin: 0;
    /*padding: 2vw;*/
}
.div_oferta_c[data-v-c7966306], .div_oferta_c_m[data-v-c7966306] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    text-align: left;
}
.div_oferta_c_m[data-v-c7966306] {
    /*background-color: red;*/
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
}
.div_oferta_compra[data-v-c7966306] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    text-align: left;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    /*background-color: yellow;*/
}
.div_timer[data-v-c7966306] {
    /*background-image: url('/static/imgs/balloon2.png');*/
    /*background-repeat: no-repeat;*/
    /*background-size: 90% 120%;*/
    /*background-position: 50% 90%;*/
    /*height: 100%;*/
    /*flex-grow: 1;*/
    /*justify-content: center;*/

    /*background-color: #6b35c4;*/
    /*background-color: white;*/
    /*align-self: center;*/
    /*justify-self: center;*/
    /*align-items: center;*/
    /*align-content: center;*/
    /*border-color: #6b35c4;*/
    /*border-width: 3px;*/
    /*border-style: solid;*/
    /*border-radius: 15px;*/
    /*padding: 5px;*/
    /*margin-left: 15%;*/

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;

    /*margin-top: 1vw;*/
}
.div_timer_m[data-v-c7966306] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.div_social[data-v-c7966306], .div_social2[data-v-c7966306] {
    /*background-color: blue;*/
    margin-top: 5vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*justify-content: center;*/
}
.div_social2[data-v-c7966306] {
    /*background-color: purple;*/
    /*top: 7vh;*/
}
.div_app[data-v-c7966306] {
    /*background-color: blue;*/
    margin-top: 2vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*justify-content: center;*/
}
.div_search[data-v-c7966306] {
    /*height: 275px;*/
    /*max-height: 275px;*/
    text-align: center;
    /*background-color: red;*/
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    /*margin-bottom: -28px;*/
    margin-top: -15px;
    height: 0px;
    /*justify-self: center;*/
    /*align-self: center;*/
    /*transform: translateY(15px);*/
}
.div_oferta_central[data-v-c7966306], .div_oferta_central_m[data-v-c7966306] {
    /*background-color: red;*/
    background-color: #fff;
    -webkit-box-shadow: 3px 3px 3px black;
            box-shadow: 3px 3px 3px black;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 50%;
    max-height: 100vh;
    height: auto;
    overflow-x: scroll;
    padding-top: 1%;
    padding-bottom: 2%;
    padding-right: 2%;
    padding-left: 2%;
    margin-right: 2%;
    margin-left: 2%;
    text-align: left;
    z-index: 1001;
}
.div_oferta_central_m[data-v-c7966306] {
    width: 100%;
}
.ofertaView[data-v-c7966306], .ofertaView_card[data-v-c7966306] {
    /*background-color: red;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: fixed;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow-x: auto;
    z-index: 3;
    /*max-height: 100vh;*/
}
.overlay_oferta[data-v-c7966306], .overlay_oferta_card[data-v-c7966306] {
    position: fixed;
    top: 0;
    opacity:0.8;
    background-color: black;
    width: 100vw;
    height: 100vh;
    z-index: 2;
}
.im_loading[data-v-c7966306], .im_loading_end[data-v-c7966306] {
    width: 80px;
    height: 80px;
    display: inline-block;
}
.im_loading_end[data-v-c7966306] {
    width: 50px;
    height: 50px;
}
.im_thumb[data-v-c7966306], .im_thumb_central[data-v-c7966306], .im_thumb_card[data-v-c7966306] {
    text-align: center;
    /*background-color: green;*/
    height: 25vh;
    max-width: 23vh;
    -o-object-fit: contain;
       object-fit: contain;
}
.im_thumb[data-v-c7966306] {
    color-scheme: only light;
}
.im_thumb_card[data-v-c7966306] {
    position: relative;
    max-width: unset;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 6px;
}
.im_thumb_m[data-v-c7966306]{
    text-align: center;
    /*background-color: green;*/
    /*height: 25vh;*/
    width: 90%;
    /*max-width: 23vh;*/
    -o-object-fit: contain;
       object-fit: contain;
}
.im_thumb_central[data-v-c7966306] {
    height: 55vh;
    max-width: 90%;
}
.im_thumb_central_m[data-v-c7966306] {
    width: 100%;
    text-align: center;
    max-height: 70vmax;
    -o-object-fit: contain;
       object-fit: contain;
    /*background-color: red;*/
}
.im_thumb_mini_central[data-v-c7966306] {
    border-width: 2px;
    border-bottom-color: black;
    border-style: solid;
    width: 50px;
    height: 50px;
    -o-object-fit: contain;
       object-fit: contain;
}
.im_cat[data-v-c7966306], .im_cat_selected[data-v-c7966306] {
    text-align: center;
    /*background-color: red;*/
    width: 2.5vh;
    margin-right: 1vh;
    -o-object-fit: contain;
       object-fit: contain;
    -webkit-filter: brightness(0.25);
            filter: brightness(0.25);
}
.im_cat_selected[data-v-c7966306] {
    /*filter: contrast(0);*/
    -webkit-filter: brightness(0%);
            filter: brightness(0%);
}
.im_logo[data-v-c7966306] {
    margin-left: 3vh;
    height: 11vh;
    /*height: 6.2vw;*/
}
.im_ttt[data-v-c7966306] {
    width: 25vw;
    width: 44vh;
    -ms-flex-item-align: center;
        align-self: center;
}
.im_ttt2[data-v-c7966306] {
    height: 12vh;
    -ms-flex-item-align: start;
        align-self: flex-start;
}
.im_ttt_m[data-v-c7966306] {
    width: 30vh;
    -ms-flex-item-align: center;
        align-self: center;
    -o-object-fit: contain;
       object-fit: contain;
}
.im_social[data-v-c7966306] {
    height: 5vh;
    width: 5vh;
    margin-right: 1vh;
    margin-left: 1vh;
}
.im_app[data-v-c7966306] {
    height: 8vh;
    width: 8vh;
    margin-right: 1vh;
    margin-left: 1vh;
}
.im_banner[data-v-c7966306], .im_banner_m[data-v-c7966306], .im_banner_m_app[data-v-c7966306] {
    /*top: 0px;*/
    margin-top: var(--size-header);
    width: 100%;
    /*height: 100%;*/
}
.im_banner_m[data-v-c7966306] {
    margin-top: calc(var(--size-header-m) - 1px);
    /*margin-top: var(--size-header-m);*/
}
.im_banner_m_app[data-v-c7966306] {
    margin-top: 0;
}
.im_balloom[data-v-c7966306] {
    position: fixed;
    /*margin-top: 25%;*/
    -ms-flex-item-align: center;
        align-self: center;
    /*width: 85%;*/
    z-index: 2;
}
.im_menu[data-v-c7966306] {
    width: 4vh;
    height: 4vh;
    margin-left: 1vh;
    /*margin-top: 1vh;*/
    vertical-align: middle;
    -o-object-fit: contain;
}
#dismiss[data-v-c7966306] {
    margin-top: 1.4vh;
}
.im_profile[data-v-c7966306], .im_profile_l[data-v-c7966306] {
    /*background-color: red;*/
    position: fixed;
    right: 2px;
    top: 2px;
    height: 5vh;
    width: 5vh;
}
.im_profile_l[data-v-c7966306] {
    top: 0px;
    margin-top: 1.5vh;
}
.div_close[data-v-c7966306] {
    width: 92%;
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    /*background-color: red;*/
}
.im_close[data-v-c7966306] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-self: flex-end;
    -ms-flex-item-align: end;
        align-self: flex-end;
    width: 30px;
    height: 30px;
}
.tx_title[data-v-c7966306], .tx_title2[data-v-c7966306], .tx_title2_card[data-v-c7966306] {
    font-size: 16pt;
    font-weight: bold;
    /*background-color: orange;*/
    display: block;
    text-align:center;
    /*margin-left: 15vw;*/
    /*margin-right: 15vw;*/
}
.tx_title2[data-v-c7966306] {
    margin-top: 3vw;
}
.tx_title2_card[data-v-c7966306] {
    margin-top: 2vh;
    /*background-color: red;*/
}
.tx_timer[data-v-c7966306], .tx_timerh[data-v-c7966306], .tx_timerhb[data-v-c7966306] {
    font-size: 13pt;
    /*font-weight: bold;*/
    /*font-style: italic;*/
    /*font-family: Impact;*/
    font-family: FocoI;
    /*font-family: Verdana;*/
    /*color: #e8842f;*/
    /*color: #e6512c;*/
    /*color: #664df4;*/
    color: white;
    /*background-color: red;*/
    /*text-shadow: -1.8px 0 black, 0 1.8px black, 1.8px 0 black, 0 -1.8px black;*/
    text-shadow: 1px 1px 0 #000,
    -1px 1px 0 #000,
    1px -1px 0 #000,
    -1px -1px 0 #000,
    0px 1px 0 #000,
    0px -1px 0 #000,
    -1px 0px 0 #000,
    1px 0px 0 #000,
    1.9px 1.9px 0 #000,
    -1.9px 1.9px 0 #000,
    1.9px -1.9px 0 #000,
    -1.9px -1.9px 0 #000,
    0px 1.9px 0 #000,
    0px -1.9px 0 #000,
    -1.9px 0px 0 #000,
    1.9px 0px 0 #000,
    1px 1.9px 0 #000,
    -1px 1.9px 0 #000,
    1px -1.9px 0 #000,
    -1px -1.9px 0 #000,
    1.9px 1px 0 #000,
    -1.9px 1px 0 #000,
    1.9px -1px 0 #000,
    -1.9px -1px 0 #000;
}
.tx_timerh[data-v-c7966306], .tx_timerhb[data-v-c7966306] {
    font-family: FocoB;
    font-style: normal;
    display:inline;
}
.tx_timerhb[data-v-c7966306] {
    font-family: RamaE;
    font-size: 23pt;
}
.tx_timerh[data-v-c7966306] {
    font-size: 18pt;
    margin-bottom: 2px;
}
.tx_lateral[data-v-c7966306], .tx_lateral_video[data-v-c7966306] {
    /*background-color: red;*/
    font-size: 15pt;
}
.im_lock[data-v-c7966306], .im_video[data-v-c7966306], .im_lock_d[data-v-c7966306], .im_regula_d[data-v-c7966306] {
    -o-object-fit: cover;
       object-fit: cover;
    width: 2vh;
    /*display: flex;*/
    margin-right: 1vh;
    -webkit-filter: brightness(0.5);
            filter: brightness(0.5);
    /*background-color: red;*/
}
.im_regula_d[data-v-c7966306] {
    width: 2.5vh;
}
.im_lock_d[data-v-c7966306] {
    width: 1.5vh;
}
.im_video[data-v-c7966306] {
    width: 2.8vh;
}
.tx_lateral_lock[data-v-c7966306], .tx_lateral_lock_d[data-v-c7966306] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*padding-top: 1px;*/
    /*background-color: yellow;*/
    font-size: 15pt;
}
.tx_lateral_lock_d[data-v-c7966306] {
    font-size: 13pt;
}
.div_lock[data-v-c7966306], .div_video[data-v-c7966306] {
    /*background-color: blue;*/
    margin-top: 3vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    vertical-align: center;
}
.div_video[data-v-c7966306] {
    margin-top: 0;
    margin-bottom: 2vh;
}
.div_lock[data-v-c7966306]:hover, .div_video[data-v-c7966306]:hover {
    background-color: #ffcf72;
}
.tx_lateral_video[data-v-c7966306] {
    width: 100%;
    display: block;
    margin-bottom: 2vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.tx_lateral_video[data-v-c7966306]:hover {
    background-color: #ffcf72;
}
.tx_lateral_s[data-v-c7966306], .tx_lateral_sb[data-v-c7966306] {
    display: inline-block;
    font-size: 12pt;
}
.tx_lateral_sb[data-v-c7966306] {
    font-weight: bold;
}
.tx_aviso[data-v-c7966306], .tx_aviso_m[data-v-c7966306] {
    font-weight: bold;
    font-size: 10pt;
    /*color: white;*/
    /*text-shadow: -1.8px 0 black, 0 1.8px black, 1.8px 0 black, 0 -1.8px black;*/
}
.tx_aviso[data-v-c7966306] {
    color: white;
    /*text-shadow: -1.8px 0 black, 0 1.8px black, 1.8px 0 black, 0 -1.8px black;*/
    text-shadow: 1px 1px 0 #000,
    -1px 1px 0 #000,
    1px -1px 0 #000,
    -1px -1px 0 #000,
    0px 1px 0 #000,
    0px -1px 0 #000,
    -1px 0px 0 #000,
    1px 0px 0 #000,
    1px 1px 0 #000,
    -1px 1px 0 #000,
    1px -1px 0 #000,
    -1px -1px 0 #000,
    0px 1px 0 #000,
    0px -1px 0 #000,
    -1px 0px 0 #000,
    1px 0px 0 #000,
    1px 1px 0 #000,
    -1px 1px 0 #000,
    1px -1px 0 #000,
    -1px -1px 0 #000,
    1px 1px 0 #000,
    -1px 1px 0 #000,
    1px -1px 0 #000,
    -1px -1px 0 #000;
}
.tx_aviso_m[data-v-c7966306] {
    font-size: 8pt;
}
.tx_oferta[data-v-c7966306]  {
    font-size: 10pt;
}
.tx_oferta_card[data-v-c7966306]  {
    /*font-size: 10pt;*/
    font-size: 1.5vh;
    max-height: 3.9vh;
    overflow: hidden;
    text-align: justify;
    padding-right: 2vw;
}
.tx_oferta_estab[data-v-c7966306] {
    font-weight: bold;
    font-size: 11pt;
}
.tx_oferta_title[data-v-c7966306] {
    font-weight: bold;
    font-size: 1.8vh;
    /*font-size: 11pt;*/
    max-height: 4.5vh;
}
.tx_oferta_central[data-v-c7966306] {
    font-size: 10pt;
    text-align: justify;
    display: block;
}
.tx_oferta_central_estab[data-v-c7966306] {
    font-size: 13pt;
    font-weight: bold;
}
.tx_oferta1[data-v-c7966306], .tx_oferta1_show[data-v-c7966306] {
    /*font-size: 10pt;*/
    font-size: 1.8vh;
    font-weight: bold;
    /*background-color: purple;*/
}
.tx_oferta1_show[data-v-c7966306] {
    font-size: 13pt;
}
.tx_oferta2[data-v-c7966306] {
    font-size: 13pt;
    font-weight: bold;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
}
.tx_oferta1_central[data-v-c7966306] {
    font-size: 13pt;
    font-weight: bold;
}
.tx_oferta2_central[data-v-c7966306] {
    font-size: 16pt;
    font-weight: bold;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
}
.tx_oferta_per[data-v-c7966306], .tx_oferta_per_central[data-v-c7966306] {
    font-size: 10pt;
    font-weight: bold;
    color: darkgreen;
    margin-left: 10px;
}
.tx_oferta_per_central[data-v-c7966306] {
    font-size: 13pt;
}
.tx_oferta_esg[data-v-c7966306] {
    font-size: 10pt;
    font-weight: bold;
    color: darkred;
}
.tx_oferta_esg_central[data-v-c7966306] {
    font-size: 13pt;
    font-weight: bold;
    color: darkred;
}
.tx_regula[data-v-c7966306] {
    text-align: justify;
    font-size: 13pt;
    font-weight: bold;
    /*color: darkred;*/
    margin-bottom: 2vh;
}
.div_regula[data-v-c7966306] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 2vw;
    padding-right: 5vw;
    padding-left: 5vw;
}
.search0[data-v-c7966306] {
    text-align: left;
    width: 100%;
}
.search_lupa[data-v-c7966306], .search_lupa_cardapio[data-v-c7966306] {
    width: 25px;
    height: 25px;
    margin-left: 2.5%;
    margin-bottom: -28px;
    position: relative;
}
.search_lupa_cardapio[data-v-c7966306] {
    margin-bottom: unset;
    margin-right: 2.5%;
}
.search1[data-v-c7966306] {
    background-image: url('/static/imgs/bg_search01m2.png');
    background-color: var(--bg-header-color);
    background-repeat: no-repeat;
    /*background-size: contain;*/
    background-size: 91% 30px;
    border: none;
    outline:none;
    position: center;
    padding-left: 10%;
    width: 100%;
    height: 30px;
    font-size: 10pt;
    text-align: left;
}
.search_cardapio[data-v-c7966306] {
    /*background-size: contain;*/
    border: none;
    outline:none;
    position: center;
    padding-left: 2%;
    width: 100%;
    height: 30px;
    font-size: 2vh;
    text-align: left;
    background-color: transparent;
}
.im_menu1[data-v-c7966306], .im_menu2[data-v-c7966306] {
    height: 35px;
    vertical-align: middle;
    margin-left: 5px;
    top: 0;
    bottom: 0;
    /*background-color: red;*/
    margin-bottom: auto;
}
.im_menu1[data-v-c7966306] {
    top: 0;
    left: 0;
    position: relative;
    margin-left: 0px;
}
.im_share[data-v-c7966306] {
    background-color:royalblue;
    /* Tamanho tem que ser proporcional ao tamanho do div_oferta que eh 28vh*/
    width: 4vh;
    height: 4vh;
}
.im_share_m[data-v-c7966306] {
    background-color:royalblue;
    /* Tamanho tem que ser proporcional ao tamanho do div_oferta_m que eh 70vmin*/
    width: 4vh;
    height: 4vh;
}
a[data-v-c7966306]:hover, a[data-v-c7966306]:visited, a[data-v-c7966306]:link, a[data-v-c7966306]:active
{
    text-decoration: none;
    color: unset;
}
.div_oferta_c_compra[data-v-c7966306] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    text-align: left;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    /*background-color: yellow;*/
}
.div_oferta_compra[data-v-c7966306] {
    background-color: white;
    position: relative;
    margin-right: 1vw;
    margin-left: 1vw;
    margin-top: 1vw;
    /*display: inline-block;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    /*background-color: white;*/
    border-color: #CCC;
    border-width: 1px;
    border-style: solid;
    padding: 2vw;
    width: 30vw;
    /*height: 38vh;*/
    min-height: 25vh;
    text-align: left;
}
@media (min-width:320px) {
    /* smartphones, iPhone, portrait 480x320 phones */
.div_oferta_compra[data-v-c7966306] {
        width: 90vw;
}
}
@media (min-width:481px)  {
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
.div_oferta_compra[data-v-c7966306] {
        width: 90vw;
}
}
@media (min-width:521px) {
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
.div_oferta_compra[data-v-c7966306] {
        width: 40vw; /* 2 lojas*!*/
}
}
@media (min-width:641px) {
    /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
.div_oferta_compra[data-v-c7966306] {
        /*width: 40vw; !* 2 lojas*!*/
        width: 27vw; /* 3 lojas */
}
}
@media (min-width:861px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
.div_oferta_compra[data-v-c7966306] {
        /*width: 27vw; !* 3 lojas *!*/
        width: 17vw; /* 4 lojas */
}
.rootviewm_compra[data-v-c7966306] {
        width: 40%;
}
.header_p1_c_m[data-v-c7966306] {
        width: 40%;
}
.ofertaView_card[data-v-c7966306] {
        width: 40%;
}
.overlay_oferta_card[data-v-c7966306] {
        width: 40%;
}
.im_thumb[data-v-c7966306] {
        margin-left: -1.5vw;
        /*background-color: red;*/
}
}
@media (min-width:1125px) {
    /* big landscape tablets, laptops, and desktops */
.div_oferta_compra[data-v-c7966306] {
        /*width: 17vw; !* 4 lojas *!*/
        width: 13vw; /* 5 lojas */
}
}
@media (min-width:1281px) {
    /* hi-res laptops and desktops */
.div_oferta_compra[data-v-c7966306] {
        /*width: 16vw; !* 4 lojas *!*/
        width: 10vw; /* 6 lojas */
}
}
[data-v-c7966306]::-webkit-scrollbar {
    display: none;
}

h1[data-v-fd5242c6], h2[data-v-fd5242c6] {
    font-weight: normal;
    font-size: 20pt;
}
.div_search_c[data-v-fd5242c6] {
    width: 30%;
    /*height: 30%;*/
    height: 4.5vh;
    background-color: white;

    border-color: #CCC;
    border-width: 1px;
    border-style: solid;

    margin-right: 2.5%;
    margin-left: 2.5%;

    text-align: left;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.search[data-v-fd5242c6] {
    /*background-image: url('/static/imgs/bg_search01.png');*/
    /*background-color: transparent;*/
    /*background-repeat: no-repeat;*/
    /*background-size: 100% 28px;*/
    background-color: white;

    font-size: 11pt;
    border: none;
    outline: none;

    width: 85%;
    height: 80%;
    /*max-width: 662px;*/

    padding-left: 2%;
}
.rootview[data-v-fd5242c6] {
    display: inline-block;
    /*background-color: red;*/
    background-color: white;

    width: 100%;
    height: 100vh;
}
.rootviewm[data-v-fd5242c6] {
    display: inline-block;
    /*background-color: red;*/
    /*background-color: lightgray;*/

    width: 100%;
    height: 100vh;
}
.header[data-v-fd5242c6] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    /*background-color: #ffe106;*/
    width: 100%;
    margin-bottom: 3vh;

    /*font-size: 0;*/
    /*height: 30vh;*/
}
.header_p1_c[data-v-fd5242c6], .header_p1_c_m[data-v-fd5242c6] {
    /*background-color: #ffe106;*/
    /*background-color: #fcd842;*/
    background-color: white;
    background-size: cover;

    position: fixed;
    z-index: 11;
    height: var(--size-header);
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.header_p1_c_m[data-v-fd5242c6] {
    height: auto;
    /*background-color: green;*/
}
.header_p1[data-v-fd5242c6], .header_p1_m[data-v-fd5242c6] {
    /*background-color: darkred;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    width: 100%;
    height: var(--size-header);
    /*height: 100%;*/
}
.header_p1_m[data-v-fd5242c6] {
    height: var(--size-header-m);
}
.bodyview[data-v-fd5242c6] {
    display: inline-block;
    /*background-color: gray;*/
    background-color: white;
    width: 100%;
    height: auto;
    margin-bottom: 50px;
}
.side_left[data-v-fd5242c6], .side_left_m[data-v-fd5242c6] {
    /*background-color: yellow;*/
    width: 24vw;
    padding-left: 1vw;
    text-align: left;
    float: left;
    height: 100%;
}
.side_left[data-v-fd5242c6] {
    border-width: 0.1vw;
    border-color: darkgray;
    border-style: none solid none none;
}
.side_left_m[data-v-fd5242c6] {
    margin-top: 2vh;
    width: 100%;
    padding-left: 2vw;
}
.side_right[data-v-fd5242c6] {
    /*background-color: darkred;*/
    width: 100vw;
    height: auto;
    /*margin-left: 25vw;*/
    border-width: 0.1vw;
    border-color: darkgray;
    border-style: none none none solid;
}
.side_left_h[data-v-fd5242c6], .side_middle_h[data-v-fd5242c6], .side_right_h[data-v-fd5242c6], .side_right_h_m[data-v-fd5242c6] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*flex: 1 1 0;*/
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    /*flex-shrink: 1;*/
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
}
.side_left_h[data-v-fd5242c6] {
    /*background-color: blue;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*align-items: center;*/
    /*margin-left: 1.5vw;*/
}
.side_middle_h[data-v-fd5242c6] {
    /*background-color: pink;*/
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-flex: 1.6;
        -ms-flex-positive: 1.6;
            flex-grow: 1.6;
}
.side_right_h[data-v-fd5242c6], .side_right_h_m[data-v-fd5242c6] {
    /*background-color: yellow;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    /*justify-content: space-between;*/
}
.side_right_h_m[data-v-fd5242c6] {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.div_share[data-v-fd5242c6], .div_share_central[data-v-fd5242c6], .div_share_m[data-v-fd5242c6], div_share_central_m[data-v-fd5242c6] {
    position: absolute;
}
.div_share[data-v-fd5242c6] {
    /* proporcional ao .div_oferta que tem tamanho 28vh*/
    margin-left: 16vh;
}
.div_share_central[data-v-fd5242c6] {
    /* proporcional ao .div_oferta_central que tem tam 50%*/
    margin-left: 43%;
}
.div_share_m[data-v-fd5242c6] {
    /* proporcional ao .div_oferta_m que tem tamanho 70vmin*/
    margin-left: 46vmin;
}
.div_share_central_m[data-v-fd5242c6] {
    /* proporcional ao .div_oferta_central_m que tem tamanho 100%*/
    margin-left: 70%;
}
.div_cats[data-v-fd5242c6] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    /*background-color: red;*/
    margin-left: 0.5vw;
}
.div_subcats[data-v-fd5242c6] {
    margin-top: 0.75vh;
    margin-bottom: 0.75vh;
    /*background-color: purple;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.div_cats[data-v-fd5242c6]:hover {
    background-color: #ffcf72;
    /*background-color: #fdd92c;*/
    /*background-color: #ffe106;*/
}
.div_oferta[data-v-fd5242c6], .div_oferta_m[data-v-fd5242c6] {
    position: relative;
    margin-right: 1vw;
    margin-left: 1vw;
    margin-top: 1vw;
    /*display: inline-block;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    /*background-color: red;*/
    /*background-color: white;*/
    border-color: #CCC;
    border-width: 1px;
    border-style: solid;
    padding: 2vw;
    width: 30vw;
    /*height: 38vh;*/
    min-height: 25vh;
    text-align: left;
}
.div_oferta_m[data-v-fd5242c6] {
    /*background-color: yellow;*/
    width: 70vmin;
    /*width: 60vmin;*/
}
.div_oferta_c[data-v-fd5242c6], .div_oferta_c_m[data-v-fd5242c6] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    text-align: left;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    /*background-color: yellow;*/
}
.div_oferta_c_m[data-v-fd5242c6] {
    /*background-color: red;*/
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
}
.div_timer[data-v-fd5242c6] {
    /*background-image: url('/static/imgs/balloon2.png');*/
    /*background-repeat: no-repeat;*/
    /*background-size: 90% 120%;*/
    /*background-position: 50% 90%;*/
    /*height: 100%;*/
    /*flex-grow: 1;*/
    /*justify-content: center;*/

    /*background-color: #6b35c4;*/
    /*background-color: white;*/
    /*align-self: center;*/
    /*justify-self: center;*/
    /*align-items: center;*/
    /*align-content: center;*/
    /*border-color: #6b35c4;*/
    /*border-width: 3px;*/
    /*border-style: solid;*/
    /*border-radius: 15px;*/
    /*padding: 5px;*/
    /*margin-left: 15%;*/

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;

    /*margin-top: 1vw;*/
}
.div_timer_m[data-v-fd5242c6] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.div_social[data-v-fd5242c6], .div_social2[data-v-fd5242c6] {
    /*background-color: blue;*/
    margin-top: 5vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*justify-content: center;*/
}
.div_social2[data-v-fd5242c6] {
    /*background-color: purple;*/
    /*top: 7vh;*/
}
.div_app[data-v-fd5242c6] {
    /*background-color: blue;*/
    margin-top: 2vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*justify-content: center;*/
}
.div_search[data-v-fd5242c6] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -ms-flex-line-pack: space-evenly;
        align-content: space-evenly;
    background-color: #333;
    height: 15vh;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.div_oferta_central[data-v-fd5242c6], .div_oferta_central_m[data-v-fd5242c6] {
    /*background-color: red;*/
    background-color: #fff;
    -webkit-box-shadow: 3px 3px 3px black;
            box-shadow: 3px 3px 3px black;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 50%;
    max-height: 100vh;
    height: auto;
    overflow-x: scroll;
    padding-top: 1%;
    padding-bottom: 2%;
    padding-right: 2%;
    padding-left: 2%;
    text-align: left;
    z-index: 1001;
}
.div_oferta_central_m[data-v-fd5242c6] {
    width: 100%;
}
.ofertaView[data-v-fd5242c6] {
    /*background-color: red;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: fixed;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow-x: auto;
    z-index: 3;
    /*max-height: 100vh;*/
}
.overlay_oferta[data-v-fd5242c6] {
    position: fixed;
    top: 0;
    opacity:0.8;
    background-color: black;
    width: 100vw;
    height: 100vh;
    z-index: 2;
}
.im_loading[data-v-fd5242c6], .im_loading_end[data-v-fd5242c6] {
    width: 80px;
    height: 80px;
    display: inline-block;
}
.im_loading_end[data-v-fd5242c6] {
    width: 50px;
    height: 50px;
}
.im_thumb[data-v-fd5242c6], .im_thumb_central[data-v-fd5242c6], .im_thumb_card[data-v-fd5242c6] {
    text-align: center;
    /*background-color: green;*/
    height: 25vh;
    max-width: 90%;
    -o-object-fit: contain;
       object-fit: contain;
}
.im_thumb_m[data-v-fd5242c6]{
    text-align: center;
    /*background-color: green;*/
    /*height: 25vh;*/
    width: 90%;
    /*max-width: 23vh;*/
    -o-object-fit: contain;
       object-fit: contain;
}
.im_thumb_central[data-v-fd5242c6] {
    height: 55vh;
    max-width: 90%;
}
.im_thumb_central_m[data-v-fd5242c6] {
    width: 100%;
    text-align: center;
    max-height: 70vmax;
    -o-object-fit: contain;
       object-fit: contain;
    /*background-color: red;*/
}
.im_thumb_mini_central[data-v-fd5242c6] {
    border-width: 2px;
    border-bottom-color: black;
    border-style: solid;
    width: 50px;
    height: 50px;
    -o-object-fit: contain;
       object-fit: contain;
}
.im_cat[data-v-fd5242c6], .im_cat_selected[data-v-fd5242c6] {
    text-align: center;
    /*background-color: red;*/
    width: 2.5vh;
    margin-right: 1vh;
    -o-object-fit: contain;
       object-fit: contain;
    -webkit-filter: brightness(0.25);
            filter: brightness(0.25);
}
.im_cat_selected[data-v-fd5242c6] {
    /*filter: contrast(0);*/
    -webkit-filter: brightness(0%);
            filter: brightness(0%);
}
.im_logo[data-v-fd5242c6] {
    margin-left: 3vh;
    height: 11vh;
    /*height: 6.2vw;*/
}
.im_ttt[data-v-fd5242c6] {
    width: 25vw;
    width: 44vh;
    -ms-flex-item-align: center;
        align-self: center;
}
.im_ttt2[data-v-fd5242c6] {
    height: 12vh;
    -ms-flex-item-align: start;
        align-self: flex-start;
}
.im_ttt_m[data-v-fd5242c6] {
    width: 30vh;
    -ms-flex-item-align: center;
        align-self: center;
}
.im_social[data-v-fd5242c6] {
    height: 5vh;
    width: 5vh;
    margin-right: 1vh;
    margin-left: 1vh;
}
.im_app[data-v-fd5242c6] {
    height: 8vh;
    width: 8vh;
    margin-right: 1vh;
    margin-left: 1vh;
}
.im_banner[data-v-fd5242c6], .im_banner_m[data-v-fd5242c6], .im_banner_m_app[data-v-fd5242c6] {
    margin-top: var(--size-header);
    width: 100%;
    /*height: 100%;*/
}
.im_banner_m[data-v-fd5242c6] {
    margin-top: var(--size-header-m);
}
.im_banner_m_app[data-v-fd5242c6] {
    margin-top: 0;
}
.im_balloom[data-v-fd5242c6] {
    position: fixed;
    /*margin-top: 25%;*/
    -ms-flex-item-align: center;
        align-self: center;
    /*width: 85%;*/
    z-index: 2;
}
.im_menu[data-v-fd5242c6] {
    width: 4.8vh;
    height: 4.8vh;
    margin-left: 1vh;
    /*margin-top: 1vh;*/
    vertical-align: middle;
}
#dismiss[data-v-fd5242c6] {
    margin-top: 1.4vh;
}
.im_profile[data-v-fd5242c6], .im_profile_l[data-v-fd5242c6] {
    /*background-color: red;*/
    position: fixed;
    right: 2px;
    top: 2px;
    height: 5vh;
    width: 5vh;
}
.im_profile_l[data-v-fd5242c6] {
    top: 0px;
    margin-top: 1.5vh;
}
.div_close[data-v-fd5242c6] {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    /*background-color: red;*/
}
.im_close[data-v-fd5242c6] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-self: flex-end;
    -ms-flex-item-align: end;
        align-self: flex-end;
    width: 30px;
    height: 30px;
}
.tx_title[data-v-fd5242c6], .tx_title2[data-v-fd5242c6] {
    font-size: 16pt;
    font-weight: bold;
    /*background-color: orange;*/
    display: block;
    text-align:center;
    margin-left: 15vw;
    margin-right: 15vw;
}
.tx_title2[data-v-fd5242c6] {
    margin-top: 3vw;
}
.tx_timer[data-v-fd5242c6], .tx_timerh[data-v-fd5242c6], .tx_timerhb[data-v-fd5242c6] {
    font-size: 13pt;
    /*font-weight: bold;*/
    /*font-style: italic;*/
    /*font-family: Impact;*/
    font-family: FocoI;
    /*font-family: Verdana;*/
    /*color: #e8842f;*/
    /*color: #e6512c;*/
    /*color: #664df4;*/
    color: white;
    /*background-color: red;*/
    /*text-shadow: -1.8px 0 black, 0 1.8px black, 1.8px 0 black, 0 -1.8px black;*/
    text-shadow: 1px 1px 0 #000,
    -1px 1px 0 #000,
    1px -1px 0 #000,
    -1px -1px 0 #000,
    0px 1px 0 #000,
    0px -1px 0 #000,
    -1px 0px 0 #000,
    1px 0px 0 #000,
    1.9px 1.9px 0 #000,
    -1.9px 1.9px 0 #000,
    1.9px -1.9px 0 #000,
    -1.9px -1.9px 0 #000,
    0px 1.9px 0 #000,
    0px -1.9px 0 #000,
    -1.9px 0px 0 #000,
    1.9px 0px 0 #000,
    1px 1.9px 0 #000,
    -1px 1.9px 0 #000,
    1px -1.9px 0 #000,
    -1px -1.9px 0 #000,
    1.9px 1px 0 #000,
    -1.9px 1px 0 #000,
    1.9px -1px 0 #000,
    -1.9px -1px 0 #000;
}
.tx_timerh[data-v-fd5242c6], .tx_timerhb[data-v-fd5242c6] {
    font-family: FocoB;
    font-style: normal;
    display:inline;
}
.tx_timerhb[data-v-fd5242c6] {
    font-family: RamaE;
    font-size: 23pt;
}
.tx_timerh[data-v-fd5242c6] {
    font-size: 18pt;
    margin-bottom: 2px;
}
.tx_lateral[data-v-fd5242c6], .tx_lateral_video[data-v-fd5242c6] {
    /*background-color: red;*/
    font-size: 15pt;
}
.tx_contato[data-v-fd5242c6] {
    color: white;
    font-size: 10pt;
    font-weight: bold;
    text-align: center;
}
.im_lock[data-v-fd5242c6], .im_video[data-v-fd5242c6], .im_lock_d[data-v-fd5242c6], .im_regula_d[data-v-fd5242c6] {
    -o-object-fit: cover;
       object-fit: cover;
    width: 2vh;
    /*display: flex;*/
    margin-right: 1vh;
    -webkit-filter: brightness(0.5);
            filter: brightness(0.5);
    /*background-color: red;*/
}
.im_regula_d[data-v-fd5242c6] {
    width: 2.5vh;
}
.im_lock_d[data-v-fd5242c6] {
    width: 1.5vh;
}
.im_video[data-v-fd5242c6] {
    width: 2.8vh;
}
.tx_lateral_lock[data-v-fd5242c6], .tx_lateral_lock_d[data-v-fd5242c6] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*padding-top: 1px;*/
    /*background-color: yellow;*/
    font-size: 15pt;
}
.tx_lateral_lock_d[data-v-fd5242c6] {
    font-size: 13pt;
}
.div_lock[data-v-fd5242c6], .div_video[data-v-fd5242c6] {
    /*background-color: blue;*/
    margin-top: 3vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    vertical-align: center;
}
.div_video[data-v-fd5242c6] {
    margin-top: 0;
    margin-bottom: 2vh;
}
.div_lock[data-v-fd5242c6]:hover, .div_video[data-v-fd5242c6]:hover {
    background-color: #ffcf72;
}
.tx_lateral_video[data-v-fd5242c6] {
    width: 100%;
    display: block;
    margin-bottom: 2vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.tx_lateral_video[data-v-fd5242c6]:hover {
    background-color: #ffcf72;
}
.tx_lateral_s[data-v-fd5242c6], .tx_lateral_sb[data-v-fd5242c6] {
    display: inline-block;
    font-size: 12pt;
}
.tx_lateral_sb[data-v-fd5242c6] {
    font-weight: bold;
}
.tx_aviso[data-v-fd5242c6], .tx_aviso_m[data-v-fd5242c6] {
    font-weight: bold;
    font-size: 10pt;
    /*color: white;*/
    /*text-shadow: -1.8px 0 black, 0 1.8px black, 1.8px 0 black, 0 -1.8px black;*/
}
.tx_aviso[data-v-fd5242c6] {
    color: white;
    /*text-shadow: -1.8px 0 black, 0 1.8px black, 1.8px 0 black, 0 -1.8px black;*/
    text-shadow: 1px 1px 0 #000,
    -1px 1px 0 #000,
    1px -1px 0 #000,
    -1px -1px 0 #000,
    0px 1px 0 #000,
    0px -1px 0 #000,
    -1px 0px 0 #000,
    1px 0px 0 #000,
    1px 1px 0 #000,
    -1px 1px 0 #000,
    1px -1px 0 #000,
    -1px -1px 0 #000,
    0px 1px 0 #000,
    0px -1px 0 #000,
    -1px 0px 0 #000,
    1px 0px 0 #000,
    1px 1px 0 #000,
    -1px 1px 0 #000,
    1px -1px 0 #000,
    -1px -1px 0 #000,
    1px 1px 0 #000,
    -1px 1px 0 #000,
    1px -1px 0 #000,
    -1px -1px 0 #000;
}
.tx_aviso_m[data-v-fd5242c6] {
    font-size: 8pt;
}
.tx_oferta[data-v-fd5242c6]  {
    font-size: 10pt;
}
.tx_oferta_estab[data-v-fd5242c6] {
    text-align: center;
    font-weight: bold;
    font-size: 11pt;
}
.tx_oferta_central[data-v-fd5242c6] {
    font-size: 13pt;
}
.tx_oferta_central_estab[data-v-fd5242c6] {
    font-size: 13pt;
    font-weight: bold;
}
.tx_oferta1[data-v-fd5242c6] {
    font-size: 10pt;
    font-weight: bold;
}
.tx_oferta2[data-v-fd5242c6] {
    font-size: 13pt;
    font-weight: bold;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
}
.tx_oferta1_central[data-v-fd5242c6] {
    font-size: 13pt;
    font-weight: bold;
}
.tx_oferta2_central[data-v-fd5242c6] {
    font-size: 16pt;
    font-weight: bold;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
}
.tx_oferta_per[data-v-fd5242c6], .tx_oferta_per_central[data-v-fd5242c6] {
    font-size: 10pt;
    font-weight: bold;
    color: darkgreen;
    margin-left: 10px;
}
.tx_oferta_per_central[data-v-fd5242c6] {
    font-size: 13pt;
}
.tx_oferta_esg[data-v-fd5242c6] {
    font-size: 10pt;
    font-weight: bold;
    color: darkred;
}
.tx_oferta_esg_central[data-v-fd5242c6] {
    font-size: 13pt;
    font-weight: bold;
    color: darkred;
}
.tx_regula[data-v-fd5242c6] {
    text-align: justify;
    font-size: 13pt;
    font-weight: bold;
    /*color: darkred;*/
    margin-bottom: 2vh;
}
.tx_site_oficial[data-v-fd5242c6] {
    color: #333;
    font-size: 15pt;
}
.div_regula[data-v-fd5242c6] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 2vw;
    padding-right: 5vw;
    padding-left: 5vw;
}
.search0[data-v-fd5242c6] {
    text-align: left;
    width: 100%;
}
.search_lupa[data-v-fd5242c6] {
    width: 25px;
    height: 25px;
    margin-left: 2.5%;
    margin-bottom: -28px;
    position: relative;
}
.search1[data-v-fd5242c6] {
    background-image: url('/static/imgs/bg_search01m2.png');
    background-color: var(--bg-header-color);
    background-repeat: no-repeat;
    /*background-size: contain;*/
    background-size: 91% 30px;
    border: none;
    outline:none;
    position: center;
    padding-left: 10%;
    width: 100%;
    height: 30px;
    font-size: 10pt;
    text-align: left;
}
.im_menu1[data-v-fd5242c6], .im_menu2[data-v-fd5242c6] {
    height: 35px;
    vertical-align: middle;
    margin-left: 5px;
    top: 0;
    bottom: 0;
    /*background-color: red;*/
    margin-bottom: auto;
}
.im_menu1[data-v-fd5242c6] {
    top: 0;
    left: 0;
    position: relative;
    margin-left: 0px;
}
.im_share[data-v-fd5242c6] {
    background-color:royalblue;
    /* Tamanho tem que ser proporcional ao tamanho do div_oferta que eh 28vh*/
    width: 4vh;
    height: 4vh;
}
.im_share_m[data-v-fd5242c6] {
    background-color:royalblue;
    /* Tamanho tem que ser proporcional ao tamanho do div_oferta_m que eh 70vmin*/
    width: 4vh;
    height: 4vh;
}

/*a:hover, a:visited, a:link, a:active*/

/*{*/

/*text-decoration: none;*/

/*color: unset;*/

/*}*/
.div_over_loja[data-v-fd5242c6], .div_over_loja_h[data-v-fd5242c6] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000AA;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.div_over_loja_h[data-v-fd5242c6] {
    display: none;
}
.div_over_loja_bt[data-v-fd5242c6] {
    background-color: #91070a;
    width: 70%;
    height: 15%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    cursor: pointer;
}
.div_como_chegar[data-v-fd5242c6] {
    width: 15%;
    height: 4.5vh;
    /*background-color: red;*/
}

/****************** SLIDER BANNER ******************/
.img_banner[data-v-fd5242c6] {
    margin: 0;
    padding: 0;
    -o-object-fit: contain;
       object-fit: contain;
}
.im_arrow[data-v-fd5242c6] {
    /*background-color: red;*/
    width: 3vw;
    height: 3vw;
    padding: 3vw;
}
.div_arrow[data-v-fd5242c6] {
    /*background-color: red;*/
    /*position: absolute;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    z-index: 2;
    height: 100%;
}
.img_container1[data-v-fd5242c6], .img_container2[data-v-fd5242c6], .img_container3[data-v-fd5242c6], .img_container4[data-v-fd5242c6], .img_container5[data-v-fd5242c6] {
    width: 100%;
    height: 100%;
    /*display: flex;*/
    /*text-align: center;*/
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-line-pack: center;
        align-content: center;
    background-position: center;
    /*background-size: 100% 100%;*/
    background-size: cover;
}
.slider[data-v-fd5242c6] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    width: 100%;
    height: 50vh;
    margin-top: var(--size-header);
    overflow: hidden;
}
.slide[data-v-fd5242c6] {
    /*width: 100%;*/
    width: 100vw;
    height: 50vh;
    position: absolute;
    -webkit-transition: 0.6s ease;
    transition: 0.6s ease;
    -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0);
}
.slide.active[data-v-fd5242c6] {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
}
.slide.active ~ .slide[data-v-fd5242c6] {
    -webkit-transform: translate(100%, 0);
            transform: translate(100%, 0);
}
.slide[data-v-fd5242c6] {
    text-align: left;
    color: white;
}

/****************** SLIDER BANNER ******************/
.bt_access_site[data-v-fd5242c6] {
    /*border-color: #004998;*/
    /*border-width: 1px;*/
    /*border-style: solid;*/
    /*border-radius: 50px;*/
    padding-right: 1vw;
    padding-left: 1vw;
    padding-top: 0.5vw;
    padding-bottom: 0.5vw;
}
.im_close_mp[data-v-fd5242c6] {
    position: absolute;
    top: 0;
    right:0;
    margin-top: 1.2vw;
    margin-right: 1.2vw;
    z-index: 100;
    width: 7vw;
}
.im_mapa[data-v-fd5242c6] {
    /*transition: all 0.9s;*/
    cursor: pointer;
    border-color: white;
    border-width: 1vw;
    border-style: solid;
    width: 93vw;
    max-height: 100vh;
    -o-object-fit: cover;
       object-fit: cover;
}
.custom-select[data-v-fd5242c6] {
    width: 30%;
    height: 4.5vh;
    background-color: white;
    border-color: #CCC;
    border-width: 1px;
    border-style: solid;

    margin-right: 2.5%;
    margin-left: 2.5%;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
}
select[data-v-fd5242c6] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;       /* Remove default arrow */
    outline: none;
    background-color: white;
    width: 100%;
    height: 100%;
    border: none;
    padding-left: 2%;
    font-size: 11pt;
}
.videoC[data-v-fd5242c6] {
    height: 100%;
    width: auto;
    display: none;
}
.videoCF[data-v-fd5242c6] {
    height: 100%;
    width: 100%;
}

/****************** MEDIAS ******************/
@media (min-width:320px)  {
    /* smartphones, iPhone, portrait 480x320 phones */
.div_oferta[data-v-fd5242c6] {
        width: 90vw;
}
.tx_site_oficial[data-v-fd5242c6] {
        color: #333;
        font-size: 9pt;
}
.bt_access_site[data-v-fd5242c6] {
        padding-right: 2vw;
        padding-left: 2vw;
}
.im_logo[data-v-fd5242c6] {
        height: 8vh;
}
.im_arrow[data-v-fd5242c6] {
        /*background-color: red;*/
        width: 3vh;
        height: 3vh;
        padding: 3vh;
}
.div_como_chegar[data-v-fd5242c6] {
        width: 80%;
}
.custom-select[data-v-fd5242c6], .div_search_c[data-v-fd5242c6] {
        width:  80%;
}
.div_search[data-v-fd5242c6] {
        height: 20vh;
}
.side_left_h[data-v-fd5242c6] {
        display: none;
}
.img_container1[data-v-fd5242c6] {
        background-image: url('/static/imgs/compra/banner03_m_3.png');
}
.img_container2[data-v-fd5242c6] {
        /*Desativado temporariamente para ficar so com o banner do delivery*/
        /*background-image: url('/static/imgs/compra/banner01_m_3.png');*/
        background-image: url('/static/imgs/compra/banner03_m_3.png');
}
.img_container3[data-v-fd5242c6] {
        /*Desativado temporariamente para ficar so com o banner do delivery*/
        /*background-image: url('/static/imgs/compra/banner02_m_3.png')*/
        background-image: url('/static/imgs/compra/banner03_m_3.png');
}
.im_close_mp[data-v-fd5242c6] {
        width: 7vw;
}
.im_mapa[data-v-fd5242c6] {
        width: 93vw;
}
.videoC[data-v-fd5242c6] {
        height: 100%;
        width: auto;
}
}
@media (min-width:481px)  {
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
.div_oferta[data-v-fd5242c6] {
        width: 90vw;
}
}
@media (min-width:521px)  {
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
.div_oferta[data-v-fd5242c6] {
        width: 40vw; /* 2 lojas*!*/
}
.tx_site_oficial[data-v-fd5242c6] {
        color: #333;
        font-size: 15pt;
}
.im_arrow[data-v-fd5242c6] {
        width: 3vw;
        height: 3vw;
        padding: 3vw;
}
.div_oferta:hover > .div_over_loja_h[data-v-fd5242c6] {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
}
}
@media (min-width:641px)  {
    /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
.div_oferta[data-v-fd5242c6] {
        /*width: 40vw; !* 2 lojas*!*/
        width: 27vw; /* 3 lojas */
}
.im_logo[data-v-fd5242c6] {
        height: 11vh;
}
.bt_access_site[data-v-fd5242c6] {
        padding-right: 1vw;
        padding-left: 1vw;
}
.img_container1[data-v-fd5242c6] {
        background-image: url('/static/imgs/compra/banner03_3.png');
}
.img_container2[data-v-fd5242c6] {
        /*Desativado temporariamente para ficar so com o banner do delivery*/
        /*background-image: url('/static/imgs/compra/banner01_3.png');*/
        background-image: url('/static/imgs/compra/banner03_3.png');
}
.img_container3[data-v-fd5242c6] {
        /*Desativado temporariamente para ficar so com o banner do delivery*/
        /*background-image: url('/static/imgs/compra/banner02_3.png');*/
        background-image: url('/static/imgs/compra/banner03_3.png');
}
.videoC[data-v-fd5242c6] {
        height: 110%;
        width: auto;
}
}
@media (min-width:861px)  {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
.div_oferta[data-v-fd5242c6] {
        /*width: 27vw; !* 3 lojas *!*/
        width: 17vw; /* 4 lojas */
}
.custom-select[data-v-fd5242c6], .div_search_c[data-v-fd5242c6] {
        width:  30%;
}
.div_como_chegar[data-v-fd5242c6] {
        width: 15%;
}
.div_search[data-v-fd5242c6] {
        height: 20vh;
}
.side_left_h[data-v-fd5242c6] {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
}
.im_close_mp[data-v-fd5242c6] {
        width: 3.5vw;
        margin-right: 18vw;
}
.im_mapa[data-v-fd5242c6] {
        width: 60vw;
}
}
@media (min-width:1125px) {
    /* big landscape tablets, laptops, and desktops */
.div_oferta[data-v-fd5242c6] {
        /*width: 17vw; !* 4 lojas *!*/
        width: 13vw; /* 5 lojas */
}
}
@media (min-width:1281px) {
    /* hi-res laptops and desktops */
.div_oferta[data-v-fd5242c6] {
        /*width: 16vw; !* 4 lojas *!*/
        width: 10vw; /* 6 lojas */
}
}

h1[data-v-091feebf], h2[data-v-091feebf] {
    font-weight: normal;
    font-size: 20pt;
}
.search[data-v-091feebf] {
    background-image: url('/static/imgs/bg_search01.png');
    background-color: transparent;
    background-repeat: no-repeat;
    /*background-size: contain;*/
    background-size: 100% 28px;
    border: none;
    outline:none;
    /*position: center;*/
    /*position: relative;*/
    /*margin-top: -15px;*/
    padding-left: 50px;
    width: 100%;
    height: 28px;
    max-width: 662px;
}
.rootview[data-v-091feebf] {
    display: inline-block;
    /*background-color: red;*/
    /*background-color: lightgray;*/

    width: 100%;
    height: 100vh;
}
.rootviewm[data-v-091feebf] {
    display: inline-block;
    /*background-color: red;*/
    /*background-color: lightgray;*/

    width: 100%;
    height: 100vh;
}
.rootviewm_compra[data-v-091feebf] {
    display: inline-block;
    /*background-color: red;*/
    /*background-color: lightgray;*/

    width: 100%;
    height: auto;
    border-color: #CCC;
    border-width: 1px;
    border-style: solid;
    min-height: 100vh;
}
.header[data-v-091feebf] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    /*background-color: #ffe106;*/
    width: 100%;
    margin-bottom: 3vh;

    /*font-size: 0;*/
    /*height: 30vh;*/
}
.header_p1_c[data-v-091feebf], .header_p1_c_m[data-v-091feebf] {
    /*background-color: #ffe106;*/
    /*background-color: #fcd842;*/
    /*background-color: green;*/
    /*background-color: var(--bg-header-color);*/
    background-image: url('/static/imgs/bg_cabecalho.jpg');
    background-size: cover;

    position: fixed;
    z-index: 2;
    height: var(--size-header);
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.header_p1_c_m[data-v-091feebf] {
    top: 0;
    height: auto;
    /*background-color: green;*/
}
.header_p1[data-v-091feebf], .header_p1_m[data-v-091feebf] {
    /*background-color: darkred;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    width: 100%;
    height: var(--size-header);
    /*height: 100%;*/
}
.header_p1_m[data-v-091feebf] {
    height: var(--size-header-m);
}
.bodyview[data-v-091feebf] {
    display: inline-block;
    /*background-color: gray;*/
    /*background-color: green;*/
    width: 100%;
    height: auto;
    margin-bottom: 50px;
}
.side_right[data-v-091feebf], .side_left[data-v-091feebf] {
    /*background-color: var(--main-bg-color);*/
    /*background-color: #ffcf72;*/
    /*background-color: #fdd92c;*/
    /*background-color: #fdf7dc;*/
}
.side_left[data-v-091feebf], .side_left_m[data-v-091feebf] {
    /*background-color: yellow;*/
    width: 24vw;
    padding-left: 1vw;
    text-align: left;
    float: left;
    height: 100%;
}
.side_left[data-v-091feebf] {
    border-width: 0.1vw;
    border-color: darkgray;
    border-style: none solid none none;
}
.side_left_m[data-v-091feebf] {
    margin-top: 2vh;
    width: 100%;
    padding-left: 2vw;
}
.side_right[data-v-091feebf] {
    /*background-color: darkred;*/
    width: 75vw;
    height: auto;
    margin-left: 25vw;
    border-width: 0.1vw;
    border-color: darkgray;
    border-style: none none none solid;
}
.side_left_h[data-v-091feebf], .side_middle_h[data-v-091feebf], .side_right_h[data-v-091feebf], .side_right_h_m[data-v-091feebf] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*flex: 1 1 0;*/
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    /*flex-shrink: 1;*/
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
}
.side_left_h[data-v-091feebf] {
    /*background-color: blue;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*align-items: center;*/
    /*margin-left: 1.5vw;*/
}
.side_middle_h[data-v-091feebf] {
    /*background-color: pink;*/
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-flex: 1.6;
        -ms-flex-positive: 1.6;
            flex-grow: 1.6;
}
.side_right_h[data-v-091feebf], .side_right_h_m[data-v-091feebf] {
    /*background-color: yellow;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    /*justify-content: space-between;*/
}
.side_right_h_m[data-v-091feebf] {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.div_share[data-v-091feebf], .div_share_central[data-v-091feebf], .div_share_m[data-v-091feebf], div_share_central_m[data-v-091feebf] {
    position: absolute;
}
.div_share[data-v-091feebf] {
    /* proporcional ao .div_oferta que tem tamanho 28vh*/
    margin-left: 16vh;
}
.div_share_central[data-v-091feebf] {
    /* proporcional ao .div_oferta_central que tem tam 50%*/
    margin-left: 43%;
}
.div_share_m[data-v-091feebf] {
    /* proporcional ao .div_oferta_m que tem tamanho 70vmin*/
    margin-left: 46vmin;
}
.div_share_central_m[data-v-091feebf] {
    /* proporcional ao .div_oferta_central_m que tem tamanho 100%*/
    margin-left: 70%;
}
.div_cats[data-v-091feebf] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    /*background-color: red;*/
    margin-left: 0.5vw;
}
.div_subcats[data-v-091feebf] {
    margin-top: 0.75vh;
    margin-bottom: 0.75vh;
    /*background-color: purple;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.div_cats[data-v-091feebf]:hover {
    background-color: #ffcf72;
    /*background-color: #fdd92c;*/
    /*background-color: #ffe106;*/
}
.div_oferta[data-v-091feebf], .div_oferta_m[data-v-091feebf], .div_oferta_m_card[data-v-091feebf] {
    /*position: relative;*/
    margin-right: 3vh;
    margin-left: 3vh;
    margin-top: 3vh;
    display: inline-block;
    /*background-color: red;*/
    background-color: white;
    -webkit-box-shadow: 3px 3px 3px lightgray;
            box-shadow: 3px 3px 3px lightgray;
    padding: 2vh;
    width: 28vh;
    /*height: 38vh;*/
    min-height: 38vh;
    text-align: left;
}
.div_oferta_m[data-v-091feebf], .div_oferta_m_card[data-v-091feebf] {
    /*background-color: yellow;*/
    width: 70vmin;
    /*width: 60vmin;*/
}
.div_oferta_m_card[data-v-091feebf] {
    background-color: white;
    /*background-color: pink;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    min-height: unset;
    min-height: 9vh;
    height: auto;
    max-height: 14vh;
    /*align-items: stretch;*/
    /*min-width: 96vw;*/
    width: 96%;
    padding: 2%;
    position: relative;

    margin-right: 0;
    margin-left: 0;
    margin-top: 0;
    border: 0px;
    border-top: 1px;
    border-color: #cccccc;
    border-style: solid;
    margin: 0;
    /*padding: 2vw;*/
}
.div_oferta_c[data-v-091feebf], .div_oferta_c_m[data-v-091feebf] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    text-align: left;
}
.div_oferta_c_m[data-v-091feebf] {
    /*background-color: red;*/
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
}
.div_oferta_compra[data-v-091feebf] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    text-align: left;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    /*background-color: yellow;*/
}
.div_timer[data-v-091feebf] {
    /*background-image: url('/static/imgs/balloon2.png');*/
    /*background-repeat: no-repeat;*/
    /*background-size: 90% 120%;*/
    /*background-position: 50% 90%;*/
    /*height: 100%;*/
    /*flex-grow: 1;*/
    /*justify-content: center;*/

    /*background-color: #6b35c4;*/
    /*background-color: white;*/
    /*align-self: center;*/
    /*justify-self: center;*/
    /*align-items: center;*/
    /*align-content: center;*/
    /*border-color: #6b35c4;*/
    /*border-width: 3px;*/
    /*border-style: solid;*/
    /*border-radius: 15px;*/
    /*padding: 5px;*/
    /*margin-left: 15%;*/

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;

    /*margin-top: 1vw;*/
}
.div_timer_m[data-v-091feebf] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.div_social[data-v-091feebf], .div_social2[data-v-091feebf] {
    /*background-color: blue;*/
    margin-top: 5vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*justify-content: center;*/
}
.div_social2[data-v-091feebf] {
    /*background-color: purple;*/
    /*top: 7vh;*/
}
.div_app[data-v-091feebf] {
    /*background-color: blue;*/
    margin-top: 2vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*justify-content: center;*/
}
.div_search[data-v-091feebf] {
    /*height: 275px;*/
    /*max-height: 275px;*/
    text-align: center;
    /*background-color: red;*/
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    /*margin-bottom: -28px;*/
    margin-top: -15px;
    height: 0px;
    /*justify-self: center;*/
    /*align-self: center;*/
    /*transform: translateY(15px);*/
}
.div_oferta_central[data-v-091feebf], .div_oferta_central_m[data-v-091feebf] {
    /*background-color: red;*/
    background-color: #fff;
    -webkit-box-shadow: 3px 3px 3px black;
            box-shadow: 3px 3px 3px black;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 50%;
    max-height: 100vh;
    height: auto;
    overflow-x: scroll;
    padding-top: 1%;
    padding-bottom: 2%;
    padding-right: 2%;
    padding-left: 2%;
    margin-right: 2%;
    margin-left: 2%;
    text-align: left;
    z-index: 1001;
}
.div_oferta_central_m[data-v-091feebf] {
    width: 100%;
}
.ofertaView[data-v-091feebf], .ofertaView_card[data-v-091feebf] {
    /*background-color: red;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: fixed;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow-x: auto;
    z-index: 3;
    /*max-height: 100vh;*/
}
.overlay_oferta[data-v-091feebf], .overlay_oferta_card[data-v-091feebf] {
    position: fixed;
    top: 0;
    opacity:0.8;
    background-color: black;
    width: 100vw;
    height: 100vh;
    z-index: 2;
}
.im_loading[data-v-091feebf], .im_loading_end[data-v-091feebf] {
    width: 80px;
    height: 80px;
    display: inline-block;
}
.im_loading_end[data-v-091feebf] {
    width: 50px;
    height: 50px;
}
.im_thumb[data-v-091feebf], .im_thumb_central[data-v-091feebf], .im_thumb_card[data-v-091feebf] {
    text-align: center;
    /*background-color: green;*/
    height: 25vh;
    max-width: 23vh;
    -o-object-fit: contain;
       object-fit: contain;
}
.im_thumb[data-v-091feebf] {
    color-scheme: only light;
}
.im_thumb_card[data-v-091feebf] {
    position: relative;
    max-width: unset;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 6px;
}
.im_thumb_m[data-v-091feebf]{
    text-align: center;
    /*background-color: green;*/
    /*height: 25vh;*/
    width: 90%;
    /*max-width: 23vh;*/
    -o-object-fit: contain;
       object-fit: contain;
}
.im_thumb_central[data-v-091feebf] {
    height: 55vh;
    max-width: 90%;
}
.im_thumb_central_m[data-v-091feebf] {
    width: 100%;
    text-align: center;
    max-height: 70vmax;
    -o-object-fit: contain;
       object-fit: contain;
    /*background-color: red;*/
}
.im_thumb_mini_central[data-v-091feebf] {
    border-width: 2px;
    border-bottom-color: black;
    border-style: solid;
    width: 50px;
    height: 50px;
    -o-object-fit: contain;
       object-fit: contain;
}
.im_cat[data-v-091feebf], .im_cat_selected[data-v-091feebf] {
    text-align: center;
    /*background-color: red;*/
    width: 2.5vh;
    margin-right: 1vh;
    -o-object-fit: contain;
       object-fit: contain;
    -webkit-filter: brightness(0.25);
            filter: brightness(0.25);
}
.im_cat_selected[data-v-091feebf] {
    /*filter: contrast(0);*/
    -webkit-filter: brightness(0%);
            filter: brightness(0%);
}
.im_logo[data-v-091feebf] {
    margin-left: 3vh;
    height: 11vh;
    /*height: 6.2vw;*/
}
.im_ttt[data-v-091feebf] {
    width: 25vw;
    width: 44vh;
    -ms-flex-item-align: center;
        align-self: center;
}
.im_ttt2[data-v-091feebf] {
    height: 12vh;
    -ms-flex-item-align: start;
        align-self: flex-start;
}
.im_ttt_m[data-v-091feebf] {
    width: 30vh;
    -ms-flex-item-align: center;
        align-self: center;
    -o-object-fit: contain;
       object-fit: contain;
}
.im_social[data-v-091feebf] {
    height: 5vh;
    width: 5vh;
    margin-right: 1vh;
    margin-left: 1vh;
}
.im_app[data-v-091feebf] {
    height: 8vh;
    width: 8vh;
    margin-right: 1vh;
    margin-left: 1vh;
}
.im_banner[data-v-091feebf], .im_banner_m[data-v-091feebf], .im_banner_m_app[data-v-091feebf] {
    /*top: 0px;*/
    margin-top: var(--size-header);
    width: 100%;
    /*height: 100%;*/
}
.im_banner_m[data-v-091feebf] {
    margin-top: calc(var(--size-header-m) - 1px);
    /*margin-top: var(--size-header-m);*/
}
.im_banner_m_app[data-v-091feebf] {
    margin-top: 0;
}
.im_balloom[data-v-091feebf] {
    position: fixed;
    /*margin-top: 25%;*/
    -ms-flex-item-align: center;
        align-self: center;
    /*width: 85%;*/
    z-index: 2;
}
.im_menu[data-v-091feebf] {
    width: 4vh;
    height: 4vh;
    margin-left: 1vh;
    /*margin-top: 1vh;*/
    vertical-align: middle;
    -o-object-fit: contain;
}
#dismiss[data-v-091feebf] {
    margin-top: 1.4vh;
}
.im_profile[data-v-091feebf], .im_profile_l[data-v-091feebf] {
    /*background-color: red;*/
    position: fixed;
    right: 2px;
    top: 2px;
    height: 5vh;
    width: 5vh;
}
.im_profile_l[data-v-091feebf] {
    top: 0px;
    margin-top: 1.5vh;
}
.div_close[data-v-091feebf] {
    width: 92%;
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    /*background-color: red;*/
}
.im_close[data-v-091feebf] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-self: flex-end;
    -ms-flex-item-align: end;
        align-self: flex-end;
    width: 30px;
    height: 30px;
}
.tx_title[data-v-091feebf], .tx_title2[data-v-091feebf], .tx_title2_card[data-v-091feebf] {
    font-size: 16pt;
    font-weight: bold;
    /*background-color: orange;*/
    display: block;
    text-align:center;
    /*margin-left: 15vw;*/
    /*margin-right: 15vw;*/
}
.tx_title2[data-v-091feebf] {
    margin-top: 3vw;
}
.tx_title2_card[data-v-091feebf] {
    margin-top: 2vh;
    /*background-color: red;*/
}
.tx_timer[data-v-091feebf], .tx_timerh[data-v-091feebf], .tx_timerhb[data-v-091feebf] {
    font-size: 13pt;
    /*font-weight: bold;*/
    /*font-style: italic;*/
    /*font-family: Impact;*/
    font-family: FocoI;
    /*font-family: Verdana;*/
    /*color: #e8842f;*/
    /*color: #e6512c;*/
    /*color: #664df4;*/
    color: white;
    /*background-color: red;*/
    /*text-shadow: -1.8px 0 black, 0 1.8px black, 1.8px 0 black, 0 -1.8px black;*/
    text-shadow: 1px 1px 0 #000,
    -1px 1px 0 #000,
    1px -1px 0 #000,
    -1px -1px 0 #000,
    0px 1px 0 #000,
    0px -1px 0 #000,
    -1px 0px 0 #000,
    1px 0px 0 #000,
    1.9px 1.9px 0 #000,
    -1.9px 1.9px 0 #000,
    1.9px -1.9px 0 #000,
    -1.9px -1.9px 0 #000,
    0px 1.9px 0 #000,
    0px -1.9px 0 #000,
    -1.9px 0px 0 #000,
    1.9px 0px 0 #000,
    1px 1.9px 0 #000,
    -1px 1.9px 0 #000,
    1px -1.9px 0 #000,
    -1px -1.9px 0 #000,
    1.9px 1px 0 #000,
    -1.9px 1px 0 #000,
    1.9px -1px 0 #000,
    -1.9px -1px 0 #000;
}
.tx_timerh[data-v-091feebf], .tx_timerhb[data-v-091feebf] {
    font-family: FocoB;
    font-style: normal;
    display:inline;
}
.tx_timerhb[data-v-091feebf] {
    font-family: RamaE;
    font-size: 23pt;
}
.tx_timerh[data-v-091feebf] {
    font-size: 18pt;
    margin-bottom: 2px;
}
.tx_lateral[data-v-091feebf], .tx_lateral_video[data-v-091feebf] {
    /*background-color: red;*/
    font-size: 15pt;
}
.im_lock[data-v-091feebf], .im_video[data-v-091feebf], .im_lock_d[data-v-091feebf], .im_regula_d[data-v-091feebf] {
    -o-object-fit: cover;
       object-fit: cover;
    width: 2vh;
    /*display: flex;*/
    margin-right: 1vh;
    -webkit-filter: brightness(0.5);
            filter: brightness(0.5);
    /*background-color: red;*/
}
.im_regula_d[data-v-091feebf] {
    width: 2.5vh;
}
.im_lock_d[data-v-091feebf] {
    width: 1.5vh;
}
.im_video[data-v-091feebf] {
    width: 2.8vh;
}
.tx_lateral_lock[data-v-091feebf], .tx_lateral_lock_d[data-v-091feebf] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*padding-top: 1px;*/
    /*background-color: yellow;*/
    font-size: 15pt;
}
.tx_lateral_lock_d[data-v-091feebf] {
    font-size: 13pt;
}
.div_lock[data-v-091feebf], .div_video[data-v-091feebf] {
    /*background-color: blue;*/
    margin-top: 3vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    vertical-align: center;
}
.div_video[data-v-091feebf] {
    margin-top: 0;
    margin-bottom: 2vh;
}
.div_lock[data-v-091feebf]:hover, .div_video[data-v-091feebf]:hover {
    background-color: #ffcf72;
}
.tx_lateral_video[data-v-091feebf] {
    width: 100%;
    display: block;
    margin-bottom: 2vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.tx_lateral_video[data-v-091feebf]:hover {
    background-color: #ffcf72;
}
.tx_lateral_s[data-v-091feebf], .tx_lateral_sb[data-v-091feebf] {
    display: inline-block;
    font-size: 12pt;
}
.tx_lateral_sb[data-v-091feebf] {
    font-weight: bold;
}
.tx_aviso[data-v-091feebf], .tx_aviso_m[data-v-091feebf] {
    font-weight: bold;
    font-size: 10pt;
    /*color: white;*/
    /*text-shadow: -1.8px 0 black, 0 1.8px black, 1.8px 0 black, 0 -1.8px black;*/
}
.tx_aviso[data-v-091feebf] {
    color: white;
    /*text-shadow: -1.8px 0 black, 0 1.8px black, 1.8px 0 black, 0 -1.8px black;*/
    text-shadow: 1px 1px 0 #000,
    -1px 1px 0 #000,
    1px -1px 0 #000,
    -1px -1px 0 #000,
    0px 1px 0 #000,
    0px -1px 0 #000,
    -1px 0px 0 #000,
    1px 0px 0 #000,
    1px 1px 0 #000,
    -1px 1px 0 #000,
    1px -1px 0 #000,
    -1px -1px 0 #000,
    0px 1px 0 #000,
    0px -1px 0 #000,
    -1px 0px 0 #000,
    1px 0px 0 #000,
    1px 1px 0 #000,
    -1px 1px 0 #000,
    1px -1px 0 #000,
    -1px -1px 0 #000,
    1px 1px 0 #000,
    -1px 1px 0 #000,
    1px -1px 0 #000,
    -1px -1px 0 #000;
}
.tx_aviso_m[data-v-091feebf] {
    font-size: 8pt;
}
.tx_oferta[data-v-091feebf]  {
    font-size: 10pt;
}
.tx_oferta_card[data-v-091feebf]  {
    /*font-size: 10pt;*/
    font-size: 1.5vh;
    max-height: 3.9vh;
    overflow: hidden;
    text-align: justify;
    padding-right: 2vw;
}
.tx_oferta_estab[data-v-091feebf] {
    font-weight: bold;
    font-size: 11pt;
}
.tx_oferta_title[data-v-091feebf] {
    font-weight: bold;
    font-size: 1.8vh;
    /*font-size: 11pt;*/
    max-height: 4.5vh;
}
.tx_oferta_central[data-v-091feebf] {
    font-size: 10pt;
    text-align: justify;
    display: block;
}
.tx_oferta_central_estab[data-v-091feebf] {
    font-size: 13pt;
    font-weight: bold;
}
.tx_oferta1[data-v-091feebf], .tx_oferta1_show[data-v-091feebf] {
    /*font-size: 10pt;*/
    font-size: 1.8vh;
    font-weight: bold;
    /*background-color: purple;*/
}
.tx_oferta1_show[data-v-091feebf] {
    font-size: 13pt;
}
.tx_oferta2[data-v-091feebf] {
    font-size: 13pt;
    font-weight: bold;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
}
.tx_oferta1_central[data-v-091feebf] {
    font-size: 13pt;
    font-weight: bold;
}
.tx_oferta2_central[data-v-091feebf] {
    font-size: 16pt;
    font-weight: bold;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
}
.tx_oferta_per[data-v-091feebf], .tx_oferta_per_central[data-v-091feebf] {
    font-size: 10pt;
    font-weight: bold;
    color: darkgreen;
    margin-left: 10px;
}
.tx_oferta_per_central[data-v-091feebf] {
    font-size: 13pt;
}
.tx_oferta_esg[data-v-091feebf] {
    font-size: 10pt;
    font-weight: bold;
    color: darkred;
}
.tx_oferta_esg_central[data-v-091feebf] {
    font-size: 13pt;
    font-weight: bold;
    color: darkred;
}
.tx_regula[data-v-091feebf] {
    text-align: justify;
    font-size: 13pt;
    font-weight: bold;
    /*color: darkred;*/
    margin-bottom: 2vh;
}
.div_regula[data-v-091feebf] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 2vw;
    padding-right: 5vw;
    padding-left: 5vw;
}
.search0[data-v-091feebf] {
    text-align: left;
    width: 100%;
}
.search_lupa[data-v-091feebf], .search_lupa_cardapio[data-v-091feebf] {
    width: 25px;
    height: 25px;
    margin-left: 2.5%;
    margin-bottom: -28px;
    position: relative;
}
.search_lupa_cardapio[data-v-091feebf] {
    margin-bottom: unset;
    margin-right: 2.5%;
}
.search1[data-v-091feebf] {
    background-image: url('/static/imgs/bg_search01m2.png');
    background-color: var(--bg-header-color);
    background-repeat: no-repeat;
    /*background-size: contain;*/
    background-size: 91% 30px;
    border: none;
    outline:none;
    position: center;
    padding-left: 10%;
    width: 100%;
    height: 30px;
    font-size: 10pt;
    text-align: left;
}
.search_cardapio[data-v-091feebf] {
    /*background-size: contain;*/
    border: none;
    outline:none;
    position: center;
    padding-left: 2%;
    width: 100%;
    height: 30px;
    font-size: 2vh;
    text-align: left;
    background-color: transparent;
}
.im_menu1[data-v-091feebf], .im_menu2[data-v-091feebf] {
    height: 35px;
    vertical-align: middle;
    margin-left: 5px;
    top: 0;
    bottom: 0;
    /*background-color: red;*/
    margin-bottom: auto;
}
.im_menu1[data-v-091feebf] {
    top: 0;
    left: 0;
    position: relative;
    margin-left: 0px;
}
.im_share[data-v-091feebf] {
    background-color:royalblue;
    /* Tamanho tem que ser proporcional ao tamanho do div_oferta que eh 28vh*/
    width: 4vh;
    height: 4vh;
}
.im_share_m[data-v-091feebf] {
    background-color:royalblue;
    /* Tamanho tem que ser proporcional ao tamanho do div_oferta_m que eh 70vmin*/
    width: 4vh;
    height: 4vh;
}
a[data-v-091feebf]:hover, a[data-v-091feebf]:visited, a[data-v-091feebf]:link, a[data-v-091feebf]:active
{
    text-decoration: none;
    color: unset;
}
.div_oferta_c_compra[data-v-091feebf] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    text-align: left;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    /*background-color: yellow;*/
}
.div_oferta_compra[data-v-091feebf] {
    background-color: white;
    position: relative;
    margin-right: 1vw;
    margin-left: 1vw;
    margin-top: 1vw;
    /*display: inline-block;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    /*background-color: white;*/
    border-color: #CCC;
    border-width: 1px;
    border-style: solid;
    padding: 2vw;
    width: 30vw;
    /*height: 38vh;*/
    min-height: 25vh;
    text-align: left;
}
@media (min-width:320px) {
    /* smartphones, iPhone, portrait 480x320 phones */
.div_oferta_compra[data-v-091feebf] {
        width: 90vw;
}
}
@media (min-width:481px)  {
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
.div_oferta_compra[data-v-091feebf] {
        width: 90vw;
}
}
@media (min-width:521px) {
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
.div_oferta_compra[data-v-091feebf] {
        width: 40vw; /* 2 lojas*!*/
}
}
@media (min-width:641px) {
    /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
.div_oferta_compra[data-v-091feebf] {
        /*width: 40vw; !* 2 lojas*!*/
        width: 27vw; /* 3 lojas */
}
}
@media (min-width:861px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
.div_oferta_compra[data-v-091feebf] {
        /*width: 27vw; !* 3 lojas *!*/
        width: 17vw; /* 4 lojas */
}
.rootviewm_compra[data-v-091feebf] {
        width: 40%;
}
.header_p1_c_m[data-v-091feebf] {
        width: 40%;
}
.ofertaView_card[data-v-091feebf] {
        width: 40%;
}
.overlay_oferta_card[data-v-091feebf] {
        width: 40%;
}
.im_thumb[data-v-091feebf] {
        margin-left: -1.5vw;
        /*background-color: red;*/
}
}
@media (min-width:1125px) {
    /* big landscape tablets, laptops, and desktops */
.div_oferta_compra[data-v-091feebf] {
        /*width: 17vw; !* 4 lojas *!*/
        width: 13vw; /* 5 lojas */
}
}
@media (min-width:1281px) {
    /* hi-res laptops and desktops */
.div_oferta_compra[data-v-091feebf] {
        /*width: 16vw; !* 4 lojas *!*/
        width: 10vw; /* 6 lojas */
}
}
[data-v-091feebf]::-webkit-scrollbar {
    display: none;
}

.tx_voltamos[data-v-7e73293c] {
    /*background: blue;*/
    font-weight: bold;
    font-size: 3vw;
}
.im_logo[data-v-7e73293c] {
    /*background: red;*/
    width: 15vw;
    height: 15vw;
}
.div_inside[data-v-7e73293c] {
    /*background: green;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.rootview[data-v-7e73293c] {
    width: 100vw;
    height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*flex-direction: column;*/
    /*background: red;*/
    /*color: #000;*/
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

